import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query DH_allChallengeTemplates($input: DH_allChallengeTemplatesInput!) {
    items: DH_allChallengeTemplates(input: $input) {
      items {
        id
        name
        picture
      }
      total
    }
  }
`;

const formatParams = (params: {
  pagination: { page: number; perPage: number };
  sort?: { field: string; order: string };
  filter?: { q: string };
}): {
  input: {
    page: number;
    perPage: number;
    sort?: { sortField: string; sortOrder: string };
    filter?: { q: string };
  };
} => {
  return {
    input: {
      page: params.pagination.page - 1,
      perPage: params.pagination.perPage,
      ...(params.sort && { sortField: params.sort.field, sortOrder: params.sort.order }),
      ...(params.filter && { filter: params.filter }),
    },
  };
};

export const GetList = () => {
  return (params: any) => {
    return {
      query: GET_LIST_QUERY,
      variables: formatParams(params),
      parseResponse: (resp: any) => {
        return {
          data: resp.data.items.items,
          total: resp.data.items.total,
        };
      },
    };
  };
};
