import React, { Fragment } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  makeStyles,
  Collapse,
  Divider,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
} from '@material-ui/core';

import { useTranslate } from 'react-admin';

interface SubMenuProps {
  handleToggle: any;
  sidebarIsOpen: any;
  isOpen: any;
  name: any;
  icon: any;
  children: any;
}

const styles = makeStyles({
  listItem: {
    paddingLeft: '1rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '1rem',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
});

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
}: SubMenuProps) => {
  const classes = styles();
  const t = useTranslate();
  return (
    <Fragment>
      <ListItem dense button onClick={handleToggle} className={classes.listItem}>
        <ListItemIcon>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
        <ListItemText
          inset
          primary={isOpen ? t(name) : ''}
          secondary={isOpen ? '' : t(name)}
          className={classes.listItemText}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense
          // @ts-ignore (je sais pas pourquoi il aime pas...)
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
