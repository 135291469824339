import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { useTranslate, useMutation } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { amaranth, shark, white } from '../../utils/colors';
import Button from '../../components/Button';
import MuiButton from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { deleteChallengeAction } from '../../redux/actions';

const useStyles = makeStyles({
  container: {
    padding: '50px 64px',
    color: shark,
    position: 'relative',
    maxWidth: '669px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: '40px',
    right: '14px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  iconWrapper: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    background: amaranth,
    borderRadius: '4px',
    '& >.icon': {
      color: white,
      fontSize: '24px',
    },
  },
  title: {
    fontWeight: 500,
    color: amaranth,
    fontSize: '24px',
    marginBottom: '16px',
  },
  summary: {
    fontWeight: 400,
    fontSize: '14px',
    marginBottom: '16px',
  },
  confirmButton: {
    width: '380px',
    boxSizing: 'border-box',
    marginBottom: '16px',
  },
  cancelButton: {
    padding: '10px 8px',
    width: '380px',
    boxSizing: 'border-box',
  },
});

interface DeleteChallengeDialogProps {
  handleClose: () => void;
  displayDialog: boolean;
  challengeID: string;
}

const DeleteChallengeDialog: FunctionComponent<DeleteChallengeDialogProps> = ({
  displayDialog,
  handleClose,
  challengeID,
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleteChallenge, { loading }] = useMutation(
    {
      type: 'delete',
      resource: 'Challenge',
      payload: {
        input: {
          id: challengeID,
        },
      },
    },
    {
      onSuccess: () => {
        history.push('/Challenge');
        dispatch(deleteChallengeAction(challengeID));
        handleClose();
      },
    }
  );

  return (
    <Dialog
      open={displayDialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.iconWrapper}>
          <InfoOutlinedIcon className="icon" />
        </div>
        <p className={classes.title}>{t('challenges.deleteChallengeDialog.title')}</p>
        <p className={classes.summary}>{t('challenges.deleteChallengeDialog.summary')}</p>
        <Button
          onClick={deleteChallenge}
          disabled={loading}
          variant="contained"
          color="primary"
          className={classes.confirmButton}
        >
          {t('challenges.deleteChallengeDialog.confirmButton')}
        </Button>
        <MuiButton
          onClick={handleClose}
          color="primary"
          className={classes.cancelButton}
          disabled={loading}
        >
          {t('challenges.deleteChallengeDialog.cancelButton')}
        </MuiButton>
      </div>
    </Dialog>
  );
};

export default DeleteChallengeDialog;
