import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const CREATE_MUTATION = gql`
  mutation createPasswordReset($email: String) {
    data: createPasswordReset(input: { type: "HrManager", email: $email }) {
      success
    }
  }
`;

export const Create = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    const createBuildQuery = builtQuery('CREATE', 'PasswordReset', params);

    console.log('[CREATE][HR_MANAGER]', params, createBuildQuery);

    return {
      ...createBuildQuery,
      query: CREATE_MUTATION,
    };
  };
};
