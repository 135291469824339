import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { Paper } from '../../components';
import { LocalCompany } from '../../@types/common';
import Spinner from '../../components/Spinner';
import { useDataProvider } from 'react-admin';

import ExternalIntegrationPending from './ExternalIntegrationPending';
import ExternalIntegrationActive from './ExternalIntegrationActive';
import NoExternalIntegration from './NoExternalIntegration';
import ListEmails from './ListEmails';

const useStyles = makeStyles({
  centeredDiv: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  button: {
    textTransform: 'none',
  },
  paper: {
    padding: '20px',
    marginTop: '32px',
  },
  titleSynchronization: {
    marginTop: 0,
    marginBottom: '1em',
    fontSize: '22px',
  },
  buttonCenter: {
    textAlign: 'center',
  },
});

export enum ExternalIntegrationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export const SynchronizationWhitelists = () => {
  const t = useTranslate();
  const styles = useStyles();
  const [statusExternalIntegration, setStatusExternalIntegration] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [synchronizationData, setSynchronizationData] = useState<any>(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    setIsLoading(true);
    dataProvider
      .getOne('Synchronization', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        if (data) {
          setSynchronizationData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dataProvider]);

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query ExternalIntegrationGoogleGetOne($id: ID!) {\n  data: ExternalIntegrationGoogleGetOne(id: $id) {\n    id\n status  }\n}`,
      }),
    })
      .then(result => result.json())
      .then(e => {
        console.log(e.data);
        if (e.data.data && e.data.data.status === ExternalIntegrationStatus.PENDING) {
          setStatusExternalIntegration(e.data.data.status);
        }

        if (e.data.data && e.data.data.status === ExternalIntegrationStatus.ACTIVE) {
          setStatusExternalIntegration(e.data.data.status);
        }
        console.error(e);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  return (
    <Paper className={styles.paper}>
      <h3 className={styles.titleSynchronization}>{t('google.workspace.title1')}</h3>
      <div>
        <div className={styles.centeredDiv}>
          <h4 className={styles.buttonCenter}>{t('google.workspace.title2')}</h4>
          <p>{t('google.workspace.p1')}</p>
          <p>
            {t('google.workspace.p2')}
            <ul>
              <li>{t('google.workspace.li.1')}</li>
              <li>{t('google.workspace.li.2')}</li>
            </ul>
          </p>
        </div>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            {statusExternalIntegration === ExternalIntegrationStatus.PENDING && (
              <div>
                <ExternalIntegrationPending
                  setStatusExternalIntegration={setStatusExternalIntegration}
                  isLoading={isLoading}
                  synchronizationData={synchronizationData}
                />
                <ListEmails
                  synchronizationData={synchronizationData}
                  isLoading={isLoading}
                />
              </div>
            )}
            {statusExternalIntegration === ExternalIntegrationStatus.ACTIVE && (
              <div>
                <ExternalIntegrationActive
                  setStatusExternalIntegration={setStatusExternalIntegration}
                />
                <ListEmails
                  synchronizationData={synchronizationData}
                  isLoading={isLoading}
                />
              </div>
            )}
            {!statusExternalIntegration && <NoExternalIntegration />}
          </>
        )}
      </div>
    </Paper>
  );
};
