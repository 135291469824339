import React, { FunctionComponent, useState } from 'react';
import {
  Notification,
  useTranslate,
  useLogin,
  useNotify,
  useRedirect,
} from 'react-admin';

import {
  CssBaseline,
  makeStyles,
  Card,
  Container,
  Typography,
  CardActions,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { sleep } from '../../utils';
import { Button, TextField } from '../../components';
import { blueGem } from '../../utils/colors';

const styles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    height: '45px',
  },
  logoContainer: {
    height: '15vh',
    minHeight: '100px',
    display: 'flex',
  },
  paper: {
    margin: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    marginTop: '15px',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
  buttonContainer: {
    padding: 0,
    paddingTop: '25px',
  },
  icon: {
    marginRight: '5px',
  },
  link: {
    paddingTop: '25px',
    float: 'right',
    color: blueGem,
  },
});

interface FormData {
  username: string;
  password: string;
}

const Login: FunctionComponent = () => {
  const RenderInput = ({
    meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    ...props
  }: {
    meta: { touched?: boolean; error?: string };
    input: any;
    [x: string]: any;
  }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      margin="normal"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      {...inputProps}
      {...props}
    />
  );
  const classes = styles();
  const translate = useTranslate();
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      await login({ username: values.username, password: values.password });
      await sleep(1000);
      setLoading(false);
      redirect('/');
    } catch (e) {
      await sleep(500);
      setLoading(false);
      if (e instanceof Error) {
        notify(e.message, 'warning');
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.logoContainer}>
        <img className={classes.logo} alt="" src={require('../../images/logo.png')} />
      </div>
      <Card>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {translate('pages.login.title')}
          </Typography>
          <Form
            onSubmit={submit}
            validate={values => {
              const errors: { username?: string; password?: string } = {
                username: '',
                password: '',
              };
              if (!values.username) {
                errors.username = translate('ra.validation.required');
              } else {
                delete errors.username;
              }
              if (!values.password) {
                errors.password = translate('ra.validation.required');
              } else {
                delete errors.password;
              }
              return errors;
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="username"
                    name="username"
                    label={translate('ra.auth.username')}
                    autoComplete="username"
                    disabled={loading}
                    placeholder="email@example.com"
                  >
                    {field => <RenderInput {...field} />}
                  </Field>
                </div>
                <div className={classes.input}>
                  <Field
                    id="password"
                    name="password"
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                    autoComplete="current-password"
                    placeholder="********"
                  >
                    {field => <RenderInput {...field} />}
                  </Field>
                </div>
                <CardActions className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className={classes.button}
                    id="login-submit"
                  >
                    {loading && (
                      <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    {translate('ra.auth.sign_in')}
                  </Button>
                </CardActions>
                <Link
                  href={`/#/send-password-reset${
                    values.username ? '/' + values.username : ''
                  }`}
                  className={classes.link}
                  id="lost-password"
                >
                  {translate('pages.login.lostPassword')}
                </Link>
              </form>
            )}
          </Form>
        </div>
      </Card>
      <Notification />
    </Container>
  );
};

export default Login;
