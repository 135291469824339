import challengeProvider from './dataProvider';
import championshipProvider from './dataProvider/championship';
import { IResourceExport } from '../../@types/dataProvider';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import List from './List';

export default {
  name: 'Challenge',
  dataProvider: {
    getOneName: 'DH_challenge',
    provider: challengeProvider,
  },
  resources: {
    list: List,
  },
  icon: CalendarTodayIcon,
  mustHaveSocial: true,
} as IResourceExport;

export const ChampionshipResource = {
  name: 'Championship',
  dataProvider: {
    getOneName: '',
    provider: championshipProvider,
  },
} as IResourceExport;
