import { IResourceExport } from '../../@types/dataProvider';
import AccountCircle from '@material-ui/icons/AccountCircle';

import WhitelistProvider from './dataProvider';
import WhitelistTabs from './whitelistTabs';

export default {
  name: 'Whitelist',
  resources: {
    list: WhitelistTabs,
  },
  icon: AccountCircle,
  dataProvider: {
    getOneName: 'whitelist',
    provider: WhitelistProvider,
  },
} as IResourceExport;
