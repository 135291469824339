import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import {
  whitelistReducers,
  challengeReducers,
  whitelistPaginatedReducers,
} from './redux/reducers';

export default ({
  authProvider,
  dataProvider,
  history,
}: {
  authProvider: any;
  dataProvider: any;
  history: any;
}) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    whitelistPaginatedReducers,
    whitelistReducers,
    challengeReducers,
  });
  const resettableAppReducer = (state: any, action: any) => {
    return reducer(action.type !== USER_LOGOUT ? state : undefined, action);
  };

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {},
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
        // add your own middlewares here
      )
      // add your own enhancers here
    )
  );
  sagaMiddleware.run(saga);
  return store;
};
