import corporationProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'CorporateCompany',
  dataProvider: {
    getOneName: 'company',
    provider: corporationProvider,
  },
} as IResourceExport;
