import React from 'react';
import { Typography, makeStyles, Paper, Button } from '@material-ui/core';
import { ChallengeTemplate } from './utils';
import { white, blueGem, alto, neonCarrot, emerald, black } from '../../utils/colors';
import { useTranslate } from 'react-admin';
import { Manager } from '../../@types/common';

const useStyles = makeStyles({
  container: {
    width: 359,
    backgroundColor: white,
    marginTop: 32,
    borderRadius: 6,
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: 194,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    objectFit: 'cover',
    objectPosition: 'top',
  },
  statusBadge: {
    position: 'absolute',
    top: 24,
    left: 24,
    padding: '5px 20px',
    borderRadius: 200,
  },
  containerNone: {
    backgroundColor: blueGem,
  },
  containerDraft: {
    backgroundColor: alto,
  },
  containerScheduled: {
    backgroundColor: '#FFF5E5',
  },
  containerPublished: {
    backgroundColor: '#C9F2DB',
  },
  textNone: {
    color: white,
  },
  textDraft: {
    color: black,
  },
  textScheduled: {
    color: neonCarrot,
  },
  textPublished: {
    color: emerald,
  },
  challengeName: {
    height: 60,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 2,
  },
  templatesWrapper: { display: 'inline-block', marginRight: '32px' },
  challengeDate: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '1.5px',
  },
  infoContainer: {
    padding: '16px',
  },
  description: {
    marginTop: 24,
  },
  cardFooter: {
    marginTop: 16,
  },
  deleteButton: {
    marginTop: 8,
  },
});

interface TemplateCardProps {
  challengeTemplate: ChallengeTemplate;
  onClick: () => void;
}

const TemplateCard = ({ challengeTemplate, onClick }: TemplateCardProps) => {
  const styles = useStyles();
  const t = useTranslate();
  let manager: Manager;
  let content = localStorage.getItem('manager');
  manager = JSON.parse(content!);
  const regex = /@gymlib\.com$/;
  const isGymlibUser = regex.test(manager.email);

  return (
    <>
      <div className={styles.templatesWrapper}>
        <Paper className={styles.container}>
          <img className={styles.image} alt="" src={challengeTemplate.picture} />
          <div className={styles.infoContainer}>
            <Typography variant="overline" className={styles.challengeDate}>
              {t('challenges.card.noPublishedDate')}
            </Typography>
            <Typography className={styles.challengeName} variant="h6">
              {challengeTemplate.name}
            </Typography>
            <div className={styles.cardFooter}>
              {isGymlibUser && (
                <Button variant="contained" color="primary" fullWidth onClick={onClick}>
                  {t('challenges.list.templatesButton')}
                </Button>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default TemplateCard;
