import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../utils/colors';
import IconButton from '@material-ui/core/IconButton';
import { Save, Cancel } from '@material-ui/icons';
import { useEffect } from 'react';

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    paddingRight: '24px',
    '&:focus-within input': {
      borderRadius: 2,
      borderColor: colors.blueGem,
      borderWidth: 2,
      borderStyle: 'solid',
      marginLeft: -2,
      marginTop: '22px',
      marginBottom: '22px',
    },
    '&:focus-within div': {
      visibility: 'visible',
    },
  },
  input: {
    outline: 'none',
    background: 'none',
    border: 'none',
    height: '100%',
    width: '100%',
    fontSize: '34px',
    marginTop: '24px',
    marginBottom: '24px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: colors.athensGray,
    },
  },
  actions: {
    position: 'absolute',
    bottom: '26px',
    right: '15px',
    visibility: 'hidden',
  },
});

interface EditableHeaderProps {
  record: any;
  source: string;
  saveChanges?: (value: string) => void | Promise<void>;
  maxLength?: number;
}

const EditableHeader = (props: EditableHeaderProps) => {
  const classes = useStyles();
  const { record, source, saveChanges, maxLength } = props;
  const [value, setValue] = useState(record[source]);

  const ref = useRef(null);

  let currentValue = record[source];

  const cancelChanges = (e: any) => {
    setValue(currentValue);
    e.currentTarget.blur();
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !(ref.current as any).contains(event.target)) {
      setValue(currentValue);
    }
  };

  const handleSaveChanges = (e: any) => {
    e.currentTarget.blur();
    currentValue = e.target.value;
    setValue(currentValue);
    record[source] = currentValue;
    saveChanges && saveChanges(value);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleChanges = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div ref={ref} className={classes.container}>
      <input
        type="text"
        className={classes.input}
        value={value}
        onChange={event => {
          handleChanges(event);
        }}
        maxLength={maxLength}
      ></input>
      <div className={classes.actions}>
        <IconButton>
          <Save
            onClick={e => {
              handleSaveChanges(e);
            }}
          />
        </IconButton>
        <IconButton onClick={e => cancelChanges(e)}>
          <Cancel />
        </IconButton>
      </div>
    </div>
  );
};

export default EditableHeader;
