import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation RemoveAuthorizedUsers($input: AuthorizedUsersRemovedInput!) {
    RemoveAuthorizedUsers(input: $input) {
      id
      users
    }
  }
`;

export const Delete = (params: any) => {
  console.log('[DELETE][AUTHORIZED_USERS]', params);

  return {
    variables: params,
    query: DELETE_MUTATION,
    parseResponse: (resp: any) => {
      return { data: {} };
    },
  };
};
