import makeStyles from '@material-ui/styles/makeStyles';
import React, { ChangeEvent, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { TableCell, TableRow, Button } from '@material-ui/core';
import { blueGem, black } from '../../utils/colors';
import { ChampionshipDAO } from './utils';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles({
  addOffice: {
    borderColor: blueGem,
    borderStyle: 'solid',
    borderWidth: 2,
  },
  addButton: {
    textAlign: 'center',
    borderBottom: 'none',
    color: black,
    display: 'flex',
    justifyContent: 'space-around',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addOfficeInput: {
    outline: 'none',
    border: 'none',
    width: '100%',
  },
  addOfficeCancelSaveButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  addOfficeCancelSaveButton: {
    padding: 0,
  },
  cellChampionShipName: {
    color: '#212936',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  importLabel: {
    display: 'flex',
    color: blueGem,
    cursor: 'pointer',
  },
});

interface AddChampionshipProps {
  challengeID: string;
  refresh: () => void;
  uploadCSV: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const AddChampionship = ({
  challengeID,
  refresh,
  uploadCSV,
}: AddChampionshipProps) => {
  const classes = useStyles();
  const t = useTranslate();
  const dataProvider = useDataProvider();

  const [displayAddChampionship, setDisplayAddChampionship] = useState(false);
  const [championshipToAdd, setChampionshipToAdd] = useState<ChampionshipDAO>();

  const handleAddChampionship = async () => {
    await dataProvider.create('Championship', {
      input: {
        challengeID: championshipToAdd!.challengeID,
        nameKey: championshipToAdd!.nameKey,
      },
    });
    refresh();
    setDisplayAddChampionship(false);
  };

  const handleCancelAddingChampionship = () => {
    setChampionshipToAdd(undefined);
    setDisplayAddChampionship(false);
  };

  return (
    <>
      {!displayAddChampionship && (
        <TableRow>
          <TableCell align="left" className={classes.addButton}>
            <label htmlFor="officeUpload" className={classes.importLabel}>
              <input
                onInput={uploadCSV}
                accept=".csv"
                type="file"
                id="officeUpload"
                name="officeUpload"
                style={{ display: 'none' }}
              />
              <NoteAddIcon fontSize="small" />
              <span>{t('companyOffice.list.header.csvUpload')}</span>
            </label>
            <span onClick={() => setDisplayAddChampionship(true)} color="primary">{`+ ${t(
              'championships.list.addChampionship.button'
            )}`}</span>
          </TableCell>
        </TableRow>
      )}
      {displayAddChampionship && (
        <TableRow>
          <TableCell align="left" className={classes.cellChampionShipName}>
            <input
              type="text"
              autoFocus
              placeholder={t('championships.list.addChampionship.namePlaceholder')}
              className={classes.addOfficeInput}
              onChange={event =>
                setChampionshipToAdd({ challengeID, nameKey: event.target.value })
              }
            ></input>
            <div className={classes.addOfficeCancelSaveButtonsContainer}>
              <Button
                color="primary"
                onClick={handleCancelAddingChampionship}
                className={classes.addOfficeCancelSaveButton}
              >
                {t('companyOffice.list.addOffice.cancel')}
              </Button>
              <Button
                color="primary"
                className={classes.addOfficeCancelSaveButton}
                disabled={!(championshipToAdd && championshipToAdd.nameKey)}
                onClick={handleAddChampionship}
              >
                {t('companyOffice.list.addOffice.add')}
              </Button>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
