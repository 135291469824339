import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { shark } from '../utils/colors';
import React from 'react';

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: shark,
  },
}));

const DarkTooltip = (props: any) => {
  const classes = useStylesBootstrap();
  const { children, ...rest } = props;

  return (
    <Tooltip arrow classes={classes} {...rest}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default DarkTooltip;
