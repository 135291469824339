import Maybe from 'graphql/tsutils/Maybe';
import { SimplifiedAuthorizedUser } from '../@types/common';

export function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  const chunkedArray = new Array(Math.ceil(arr.length / chunkSize)).fill([]);
  arr.forEach((item: T, index: number) => {
    chunkedArray[Math.floor(index / chunkSize)] = chunkedArray[
      Math.floor(index / chunkSize)
    ].concat(item);
  });
  return chunkedArray;
}

export const AmountFormatter = {
  parse: (value: number) => {
    return value * 100;
  },
  format: (value: number) => {
    return value / 100;
  },
};

// eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function formatEmail(email: Maybe<string>) {
  const sanitizedEmail = email ? email.replace(/\s+/g, '').toLowerCase() : '';
  return EMAIL_REGEX.test(sanitizedEmail) ? sanitizedEmail : '';
}

export function formatRefKey(refKey?: Maybe<string>) {
  return refKey ? refKey.replace(/\s+/g, '').toLowerCase() : '';
}

export function whitelistCleaner(data: string[][]) {
  return data
    .map(([email]) => email)
    .reduce<string[]>(
      (acc, email) => (acc.includes(email) ? acc : acc.concat(formatEmail(email))),
      []
    )
    .filter((email: string) => EMAIL_REGEX.test(email));
}

export function cleanCSVWithRefKeyOutput(data: [Maybe<string>, Maybe<string>][]) {
  const uniqueValidEmployeesIdentifiersMap = new Map<string, Maybe<string>>();
  data.forEach(line => {
    const refKey = line[0];
    const email = line.length > 1 ? line[1] : null;
    if (refKey) {
      const sanitizedRefKey = formatRefKey(refKey);
      const sanitizedEmail = formatEmail(email);
      uniqueValidEmployeesIdentifiersMap.set(sanitizedRefKey, sanitizedEmail);
    }
  });

  const employeeIdentifiersToSync = Array.from(uniqueValidEmployeesIdentifiersMap).map(
    ([refKey, email]) => ({
      refKey,
      email,
    })
  );
  return employeeIdentifiersToSync as SimplifiedAuthorizedUser[];
}

export const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const safeJSONParse = (input: string, fallback: any = []) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    console.error('Error while parsing input', e);
    return fallback;
  }
};

export const isInternalEmail = () => {
  let content = localStorage.getItem('manager');
  if (!content) return false;
  try {
    const manager = JSON.parse(content);
    const regexp = new RegExp(/@(gymlib|wellpass|egym)/);
    return !!manager.email.match(regexp);
  } catch (error) {
    console.error('Error while parsing manager', error);
  }

  return false;
};
