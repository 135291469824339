import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const UPDATE_HR_MANAGER_PASSWORD_MUTATION = gql`
  mutation changeHrManagerPassword($input: ChangeHrManagerPasswordInput!) {
    data: changeHrManagerPassword(input: $input) {
      status
    }
  }
`;

export const Update = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    if (params.data.input && params.data.input.newPassword) {
      console.log('[UPDATE][HR_MANAGER_PASSWORD]', params);
      return {
        ...builtQuery('UPDATE', 'HrManager', params),
        query: UPDATE_HR_MANAGER_PASSWORD_MUTATION,
        parseResponse: (resp: any) => {
          if (resp.data.data.status) {
            return { data: { id: 'someID' } }; // we need to return some id, so we return "someID" lol
          }
        },
      };
    }
  };
};
