import Dialog from '@material-ui/core/Dialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useMutation, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Paper } from '../../components';
import { updateWhitelistAction } from '../../redux/actions';
import { blueGem, emerald, scarpaFlow, shark } from '../../utils/colors';
import { LocalCompany } from '../../@types/common';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Button, Avatar } from '@material-ui/core';

const useStyles = makeStyles({
  mainWrapper: {
    margin: '70px 10px',

    '& .section': {
      padding: '70px 70px 24px 70px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: shark,

      '& .title': {
        fontSize: '34px',
        margin: '0 0 20px 0',
        fontWeight: 'normal',
        letterSpacing: '0.25px',
      },
      '& .subtitle': {
        fontSize: '18px',
        maxWidth: '475px',
        marginBottom: '50px',
        letterSpacing: '0.5px',
      },
      '& .addWhitelistButton': {
        display: 'flex',
        marginBottom: '25px',
        alignItems: 'center',
        color: 'white',
      },

      '& .downloadButton': { letterSpacing: '0.75px', marginBottom: '90px' },
      '& .footer p': {
        margin: 0,
        fontSize: '12px',
        letterSpacing: '0.4px',
        color: scarpaFlow,
      },
    },
  },

  uploadedDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '54px 76px',

    '& .icon': {
      color: emerald,
      fontSize: '40px',
      marginBottom: '20px',
    },

    '& .description': {
      fontWeight: 500,
      width: '302px',
      fontSize: '20px',
      letterSpacing: '0.15px',
    },

    '& .confirmButton': {
      marginTop: '48px',
      width: '327px',
    },
  },

  button: {
    textTransform: 'none',
  },

  confirmDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '594px',
    padding: '48px 0 32px 0',

    '& .title': {
      margin: '0 0 35px 0',
      fontWeight: 600,
      fontSize: '20px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      width: '302px',
      color: shark,
    },

    '& .close': {
      position: 'absolute',
      cursor: 'pointer',
      top: '21px',
      right: '28px',
      color: shark,
    },

    '& .confirmButton': {
      width: '327px',
      margin: '30px 0 10px 0',
    },

    '& .downloadButton': {
      letterSpacing: '0.75px',
      color: blueGem,
    },
  },
});

const customTheme = createTheme({
  palette: {
    secondary: {
      // works
      main: '#fff',
      contrastText: '#808080',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const WhitelistUploadedDialog = ({
  employeeCount,
  handleClose,
  isOpen,
}: {
  employeeCount: number;
  handleClose: () => void;
  isOpen: boolean;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.uploadedDialogWrapper}>
        <CheckCircleIcon className="icon" fontSize="large" />
        <span className="description">
          {t('whitelist.empty.dialog.text', { smart_count: employeeCount })}{' '}
        </span>
        <Button
          onClick={handleClose}
          className="confirmButton"
          variant="contained"
          color="primary"
        >
          {t('whitelist.empty.dialog.button')}
        </Button>
      </div>
    </Dialog>
  );
};

const ConfirmDialog = ({
  handleClose,
  isOpen,
  setWhitelist,
}: {
  handleClose: () => void;
  isOpen: boolean;
  setWhitelist: (emails: string[]) => void;
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.confirmDialogWrapper}>
        <CloseIcon className="close" onClick={handleClose} />
        <h2 className="title">{t('whitelist.empty.confirmDialog.title')}</h2>
        <img
          alt="whitelist"
          src={require('../../images/whitelist-confirm-upload.png')}
        ></img>
      </div>
    </Dialog>
  );
};

const NoWhitelist = () => {
  const t = useTranslate();
  const classes = useStyles();
  const [whitelist, setWhitelist] = useState((null as unknown) as string[]);
  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
  const [openedUploadedDialog, setOpenedUploadedDialog] = useState(false);
  const [openedConfirmationDialog, setOpenedConfirmationDialog] = useState(false);
  const dispatch = useDispatch();

  const [createWhitelist, { data }] = useMutation({
    type: 'create',
    resource: 'Whitelist',
    payload: { data: { input: { companyID: currentCompany.id, emails: whitelist } } },
  });

  useEffect(() => {
    if (!whitelist || !createWhitelist) return;
    createWhitelist();
  }, [whitelist, createWhitelist]);

  useEffect(() => {
    if (!data) return;
    setOpenedUploadedDialog(true);
  }, [data]);

  const handleClick = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    let body = JSON.stringify({
      variables: { id: currentCompany.id },
      query: `query oauthGoogle($id: ID!) {\n  data: BO_OauthGoogle(id: $id) {\n    oauth_url  }\n}`,
    });
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'apollo-require-preflight': 'true',
      },
      credentials: 'include',
      body: body,
    })
      .then(result => result.json())
      .then(e => {
        console.error(e);
        window.open(e.data.data.oauth_url, '_blank');
      });
  };

  const handleClickSyncUsers = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'apollo-require-preflight': 'true',
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query syncUsers($id: ID!) {\n  data: SyncUsers(id: $id) {\n    emails  }\n}`,
      }),
    })
      .then(result => {
        window.location.reload();
        result.json();
      })
      .then(error => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={classes.mainWrapper}>
        <Button variant="contained" color="primary" onClick={handleClickSyncUsers}>
          {'Synchronization'}
        </Button>

        <ThemeProvider theme={customTheme}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClick}
            startIcon={
              // eslint-disable-next-line react/jsx-no-undef
              <Avatar
                src={
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/768px-Google_%22G%22_Logo.svg.png'
                }
              />
            }
          >
            {'Create Google integration'}
          </Button>
        </ThemeProvider>

        <Paper className="section">
          <h2 className="title">{t('whitelist.empty.title')}</h2>
          <p className="subtitle">{t('whitelist.empty.subtitle')}</p>
          <div className="footer">
            <p>{t('whitelist.empty.example.desc-1')}</p>
            <p>{t('whitelist.empty.example.desc-2')}</p>
          </div>
        </Paper>
      </div>
      <ConfirmDialog
        isOpen={openedConfirmationDialog}
        setWhitelist={setWhitelist}
        handleClose={() => setOpenedConfirmationDialog(false)}
      />
      {whitelist && (
        <WhitelistUploadedDialog
          employeeCount={whitelist.length}
          isOpen={openedUploadedDialog}
          handleClose={() => {
            dispatch(updateWhitelistAction(data.whitelist.emails));
            setOpenedUploadedDialog(false);
          }}
        />
      )}
    </>
  );
};

export default NoWhitelist;
