import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const CREATE_MUTATION = gql`
  mutation createWhitelist($input: CreateWhitelistInput!) {
    data: createWhitelist(input: $input) {
      whitelist {
        emails
      }
    }
  }
`;

export const Create = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    const createBuildQuery = builtQuery('CREATE', 'Whitelist', params);

    console.log('[CREATE][WHITELIST]', params, createBuildQuery);

    return {
      ...createBuildQuery,
      query: CREATE_MUTATION,
    };
  };
};
