import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const UPDATE_CORPORATE_COMPANY_MUTATION = gql`
  mutation DH_updateChallenge($input: DH_UpdateChallengeInput!) {
    data: DH_updateChallenge(input: $input) {
      challenge {
        id
        name
        status
        publishedDate
        picture
        championships {
          edges {
            cursor
            node {
              id
              name
              channel {
                id
                userCount
              }
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const Update = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    if (params.data.input && params.data.input.id) {
      console.log('[UPDATE][CHALLENGE]', params);
      return {
        ...builtQuery('UPDATE', 'Challenge', params),
        query: UPDATE_CORPORATE_COMPANY_MUTATION,
        parseResponse: (resp: any) => {
          if (resp.data.data.challenge.id) {
            return { data: resp.data.data.challenge };
          }
        },
      };
    }
  };
};
