import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { emerald } from '../../utils/colors';
import { Button, Avatar } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { LocalCompany } from '../../@types/common';

const useStyles = makeStyles({
  p: {
    color: emerald,
  },
  button: {
    textTransform: 'none',
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const customTheme = createTheme({
  palette: {
    secondary: {
      // works
      main: '#fff',
      contrastText: '#808080',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const NoExternalIntegration = () => {
  const t = useTranslate();
  const styles = useStyles();

  const handleClick = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    let body = JSON.stringify({
      variables: { id: currentCompany.id },
      query: `query oauthGoogle($id: ID!) {\n  data: BO_OauthGoogle(id: $id) {\n    oauth_url  }\n}`,
    });
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',
      body: body,
    })
      .then(result => result.json())
      .then(e => {
        console.error(e);
        window.open(e.data.data.oauth_url, '_blank');
      });
  };
  return (
    <div className={styles.center}>
      <ThemeProvider theme={customTheme}>
        <Button
          className={styles.button}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleClick}
          startIcon={
            // eslint-disable-next-line react/jsx-no-undef
            <Avatar
              src={
                'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/768px-Google_%22G%22_Logo.svg.png'
              }
            />
          }
        >
          {t('google.workspace.integration')}
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default NoExternalIntegration;
