import React, { useEffect } from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import AppBar from './AppBar';
import { white } from '../utils/colors';

import { useLocation } from 'react-router-dom';
import { createEvent } from '../utils/analytics';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    createEvent('page_view', { page_path: location.pathname + location.search });
  }, [location]);
};

const CustomLayout = (props: any) => {
  usePageTracking();
  return (
    <Layout
      style={{ background: white, padding: 0 }}
      {...props}
      menu={Menu}
      appBar={AppBar}
    />
  );
};

export default CustomLayout;
