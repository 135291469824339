import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useDataProvider } from 'react-admin';
import { Paper } from '../../components';

const useStyles = makeStyles({
  headingTableCell: {
    fontWeight: 'bold',
  },
});

const OlderWhitelists = () => {
  const t = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [olderWhitelists, setOlderWhitelists] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dataProvider
      .getList('Whitelist', {
        pagination: {
          page: page + 1,
          perPage,
        },
        sort: { field: 'createdAt', order: 'DESC' },
      })
      .then((res: any) => {
        setOlderWhitelists(res.data);
        setTotal(res.total);
      });
  }, [dataProvider, page, perPage]);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow className={classes.headingTableCell}>
            <TableCell>{t('whitelist.older.table.label')}</TableCell>
            <TableCell>{t('whitelist.older.table.createdBy')}</TableCell>
            <TableCell>{t('whitelist.older.table.count')}</TableCell>
            <TableCell>{t('whitelist.older.table.createdAt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {olderWhitelists.map(
            ({ createdAt, name, whitelistSize, id, fromHrManager, fromUser }: any) => (
              <TableRow key={id}>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">
                  {fromUser ? 'Gymlib' : fromHrManager ? fromHrManager : 'Gymlib'}
                </TableCell>
                <TableCell align="left">{whitelistSize}</TableCell>
                <TableCell align="left">
                  {new Date(createdAt).toLocaleDateString()}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelRowsPerPage={t('whitelist.older.rowsPerPageLabel')}
        count={total}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setPerPage(parseInt(e!.target!.value))}
      />
    </Paper>
  );
};

export default OlderWhitelists;
