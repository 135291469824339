import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import NotificationsIcon from '@material-ui/icons/Notifications';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslate } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { shark, blueGem, frenchGray } from '../../utils/colors';
import Button from '../../components/Button';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: '50px 64px',
    color: shark,
    position: 'relative',
    maxWidth: '669px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  iconWrapper: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    '& >.icon': {
      color: blueGem,
      fontSize: '38px',
    },
  },
  title: {
    marginTop: 24,
    color: frenchGray,
  },
  summary: {
    textAlign: 'center',
    marginBottom: 24,
  },
  confirmButton: {
    width: '380px',
    boxSizing: 'border-box',
    marginBottom: '16px',
  },
});

interface CannotUpdateChallengeDialogProps {
  handleClose: () => void;
  displayDialog: boolean;
  onClick: () => void;
}

const CannotUpdateChallengeDialog: FunctionComponent<CannotUpdateChallengeDialogProps> = ({
  displayDialog,
  handleClose,
  onClick,
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Dialog
      open={displayDialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth="sm"
    >
      <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.iconWrapper}>
          <NotificationsIcon className="icon" />
        </div>
        <Typography className={classes.title} variant="overline">
          {t('challenges.cannotUpdateDialog.title')}
        </Typography>
        <Typography className={classes.summary} variant="h5">
          {t('challenges.cannotUpdateDialog.summary')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          onClick={onClick}
        >
          {t('challenges.cannotUpdateDialog.button')}
        </Button>
      </div>
    </Dialog>
  );
};

export default CannotUpdateChallengeDialog;
