import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslate } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { shark, blueGem } from '../../utils/colors';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles({
  container: {
    padding: '50px 64px',
    color: shark,
    position: 'relative',
    maxWidth: '669px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  closeIcon: {
    position: 'absolute',
    top: '21px',
    right: '21px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 500,
    color: blueGem,
    fontSize: '34px',
    marginBottom: '8px',
  },
  subtitle: {
    fontSize: '20px',
    marginBottom: '8px',
    fontWeight: 300,
  },
  img: {
    marginBottom: '8px',
  },
  summary: {
    fontWeight: 400,
    fontSize: '14px',
    marginBottom: '40px',
  },
  closeButton: {
    padding: '10px 16px',
    boxSizing: 'border-box',
  },
});

interface DiscoverChallengeDialogProps {
  handleClose: () => void;
  displayDialog: boolean;
}

const DiscoverChallengeDialog: FunctionComponent<DiscoverChallengeDialogProps> = ({
  displayDialog,
  handleClose,
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Dialog
      open={displayDialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.title}>
          {t('challenges.discoverChallengeDialog.title')}
        </div>
        <p className={classes.subtitle}>
          {t('challenges.discoverChallengeDialog.subtitle')}
        </p>
        <img
          alt=""
          src={require('./assets/discover-challenge.png')}
          width="100%"
          className={classes.img}
        />
        <p className={classes.summary}>
          {t('challenges.discoverChallengeDialog.summary')}
        </p>
        <MuiButton
          variant="contained"
          onClick={handleClose}
          color="primary"
          className={classes.closeButton}
        >
          {t('challenges.discoverChallengeDialog.closeButton')}
        </MuiButton>
      </div>
    </Dialog>
  );
};

export default DiscoverChallengeDialog;
