import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const link = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    'apollo-require-preflight': true,
  },
  credentials: 'include',
});
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION($email: String!, $password: String!) {
    loginHrManager(input: { email: $email, password: $password }) {
      token
      manager {
        companies {
          id
          name
          hasFeed
          hasChallenge
          usersCanRegisterWithIdentifier
          externalIntegration {
            id
          }
        }
        email
        id
      }
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    DH_logout
  }
`;

export interface ManagerLogin {
  token: string;
  manager: {
    id: string;
    email: string;
    companies: {
      id: string;
      name: string;
      hasFeed: boolean;
      hasChallenge: boolean;
      usersCanRegisterWithIdentifier: boolean;
      externalIntegration: { id: string };
    }[];
  };
}

export const managerLogin = async (
  email: string,
  password: string
): Promise<ManagerLogin> => {
  const {
    data: { loginHrManager },
  }: { data: { loginHrManager: ManagerLogin } } = (await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      email: email,
      password: password,
    },
  })) as any;
  return loginHrManager;
};

export const managerLogout = async (): Promise<boolean> => {
  const {
    data: { DH_logout },
  }: { data: { DH_logout: boolean } } = (await client.mutate({
    mutation: LOGOUT_MUTATION,
    variables: {},
  })) as any;
  return DH_logout;
};
