import { AllPossibleRequestResolver } from '../../../@types/dataProvider';
import { Delete } from './Delete';
import { GetOne } from './GetOne';
import { Update } from './Update';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne,
    DELETE: Delete,
    UPDATE: Update,
  };
};
