import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation DH_CreateChampionship($input: DH_CreateChampionshipInput!) {
    data: DH_CreateChampionship(input: $input) {
      championship {
        id
        name
      }
    }
  }
`;

export const Create = () => {
  return (params: any) => {
    console.log('[CREATE][CHAMPIONSHIP]', params);
    return {
      query: CREATE_MUTATION,
      variables: params,
      parseResponse: (resp: any) => {
        if (resp.data.data.championship.id) {
          return { data: resp.data.data.championship };
        }
      },
    };
  };
};
