import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import BackupIcon from '@material-ui/icons/Backup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import { parse as convertFromCSV, unparse as convertToCSV } from 'papaparse';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { downloadCSV, useMutation, useTranslate } from 'react-admin';
import { Button, Paper } from '../../components';
import { whitelistCleaner, cleanCSVWithRefKeyOutput } from '../../utils';
import { blueGem, emerald, scarpaFlow, shark } from '../../utils/colors';
import Maybe from 'graphql/tsutils/Maybe';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  mainWrapper: {
    margin: '70px 10px',

    '& .section': {
      padding: '70px 70px 24px 70px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: shark,

      '& .title': {
        fontSize: '34px',
        margin: '0 0 20px 0',
        fontWeight: 'normal',
        letterSpacing: '0.25px',
      },
      '& .subtitle': {
        fontSize: '18px',
        maxWidth: '475px',
        marginBottom: '50px',
        letterSpacing: '0.5px',
      },
      '& .addWhitelistButton': {
        display: 'flex',
        marginBottom: '25px',
        alignItems: 'center',
        color: 'white',

        '& .addWhitelistLabel': {
          display: 'inline-flex',
          alignItems: 'center',
          padding: '5px 10px',
          borderRadius: '5px',
          margin: '0 auto',
          cursor: 'pointer',
          textTransform: 'uppercase',
          letterSpacing: '0.75px',

          '& .icon': {
            marginLeft: '10px',
            opacity: 1,
          },
        },
      },

      '& .downloadButton': { letterSpacing: '0.75px', marginBottom: '90px' },
      '& .footer p': {
        margin: 0,
        fontSize: '12px',
        letterSpacing: '0.4px',
        color: scarpaFlow,
      },
    },
  },

  uploadedDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '54px 76px',

    '& .icon': {
      color: emerald,
      fontSize: '40px',
      marginBottom: '20px',
    },

    '& .description': {
      fontWeight: 500,
      width: '302px',
      fontSize: '20px',
      letterSpacing: '0.15px',
    },

    '& .confirmButton': {
      marginTop: '48px',
      width: '327px',
    },
  },

  loadingDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '54px 76px',

    '& .description': {
      fontWeight: 500,
      width: '450px',
      fontSize: '20px',
      letterSpacing: '0.15px',
    },
  },

  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '54px 76px',
  },

  confirmDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '594px',
    padding: '48px 0 32px 0',

    '& .title': {
      margin: '0 0 35px 0',
      fontWeight: 600,
      fontSize: '20px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      width: '302px',
      color: shark,
    },

    '& .close': {
      position: 'absolute',
      cursor: 'pointer',
      top: '21px',
      right: '28px',
      color: shark,
    },

    '& .confirmButton': {
      width: '327px',
      margin: '30px 0 10px 0',
    },

    '& .downloadButton': {
      letterSpacing: '0.75px',
      color: blueGem,
    },
  },
});

const exportExample = (usersCanRegisterWithIdentifier?: boolean) => {
  let csv: any = '';
  if (usersCanRegisterWithIdentifier) {
    csv = convertToCSV({
      data: [
        ['24738', 'example-1@example.com'],
        ['39654', 'example-2@example.com'],
      ],
      fields: ['Company ID', 'email'],
    });
  } else {
    csv = convertToCSV({
      data: [['example-1@example.com'], ['example-2@example.com']],
      fields: ['email'],
    });
  }
  downloadCSV(csv, 'whitelist');
};

const WhitelistUploadedDialog = ({
  employeeCount,
  handleClose,
  isOpen,
}: {
  employeeCount: number;
  handleClose: () => void;
  isOpen: boolean;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.uploadedDialogWrapper}>
        <CheckCircleIcon className="icon" fontSize="large" />
        <span className="description">
          {t('whitelist.empty.dialog.text', { smart_count: employeeCount })}{' '}
        </span>
        <Button
          onClick={handleClose}
          className="confirmButton"
          variant="contained"
          color="primary"
        >
          {t('whitelist.empty.dialog.button')}
        </Button>
      </div>
    </Dialog>
  );
};

const ConfirmDialog = ({
  handleClose,
  isOpen,
  setWhitelist,
  setAuthorizedUsersAsString,
  setAuthorizedUsersCount,
}: {
  handleClose: () => void;
  isOpen: boolean;
  setWhitelist: (emails: string[]) => void;
  setAuthorizedUsersAsString: (input: string) => void;
  setAuthorizedUsersCount: Function;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);

  const uploadCSV = (
    e: ChangeEvent<HTMLInputElement>,
    usersCanRegisterWithIdentifier?: boolean
  ) => {
    setAuthorizedUsersAsString('');
    setAuthorizedUsersCount(0);
    convertFromCSV(e!.target!.files![0], {
      skipEmptyLines: true,
      complete: (results: { data: [][] }) => {
        if (!usersCanRegisterWithIdentifier) {
          const cleanWhitelist = whitelistCleaner(results.data);
          setWhitelist(cleanWhitelist);
        } else {
          //remove first line as it is the header
          results.data.shift();
          const cleanWhitelist = cleanCSVWithRefKeyOutput(
            (results.data as unknown) as [Maybe<string>, Maybe<string>][]
          );
          const authorizedUsersAsString = JSON.stringify(cleanWhitelist);
          setAuthorizedUsersAsString(authorizedUsersAsString);
          setAuthorizedUsersCount(cleanWhitelist.length);
        }
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.confirmDialogWrapper}>
        <CloseIcon className="close" onClick={handleClose} />
        <h2 className="title">{t('whitelist.empty.confirmDialog.title')}</h2>
        {currentCompany.usersCanRegisterWithIdentifier ? (
          <img
            alt="whitelist"
            src={require('../../images/whitelist-with-identifiers.png')}
            width="450"
          />
        ) : (
          <img
            alt="whitelist"
            src={require('../../images/whitelist-confirm-upload.png')}
          />
        )}
        <Button className="confirmButton">
          <label className="addWhitelistLabel">
            <input
              onChange={e => {
                uploadCSV(e, currentCompany.usersCanRegisterWithIdentifier);
                handleClose();
              }}
              accept=".csv"
              type="file"
              id="whitelistUpload"
              name="whitelistUpload"
              style={{ display: 'none' }}
            />
            <span>{t('whitelist.empty.confirmDialog.confirmButton')}</span>
          </label>
        </Button>
        <MuiButton
          className="downloadButton"
          onClick={() => {
            exportExample(currentCompany.usersCanRegisterWithIdentifier);
          }}
        >
          {t('whitelist.empty.confirmDialog.cancelButton')}
        </MuiButton>
      </div>
    </Dialog>
  );
};

export const WhitelistLoadingDialog = ({
  isOpen,
  usersCount,
}: {
  isOpen: boolean;
  usersCount: number;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <Dialog open={isOpen}>
      {usersCount <= 30000 ? (
        <div className={classes.spinnerWrapper}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <div className={classes.loadingDialogWrapper}>
          <span className="description">
            {t('whitelist.bigupload.dialog.part1')}{' '}
            <b>{t('whitelist.bigupload.dialog.part2')}</b>{' '}
            {t('whitelist.bigupload.dialog.part3')}
          </span>
        </div>
      )}
    </Dialog>
  );
};

const NoWhitelist = ({ fetchData }: { fetchData: Function }) => {
  const t = useTranslate();
  const classes = useStyles();
  const [whitelist, setWhitelist] = useState((null as unknown) as string[]);
  const [authorizedUsersAsString, setAuthorizedUsersAsString] = useState('');
  const [authorizedUsersCount, setAuthorizedUsersCount] = useState(0);
  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
  const [openedUploadedDialog, setOpenedUploadedDialog] = useState(false);
  const [createWhitelistLoadingDialog, setCreateWhitelistLoadingDialog] = useState(false);
  const [openedConfirmationDialog, setOpenedConfirmationDialog] = useState(false);

  const [createWhitelist, { data, loading: createWhitelistLoading }] = useMutation({
    type: 'create',
    resource: 'Whitelist',
    payload: {
      data: {
        input: {
          companyID: currentCompany.id,
          ...(currentCompany.usersCanRegisterWithIdentifier
            ? { authorizedUsers: authorizedUsersAsString }
            : { emails: whitelist }),
        },
      },
    },
  });

  useEffect(() => {
    if (!(whitelist || authorizedUsersAsString) || !createWhitelist) return;
    if (whitelist?.length > 0 || authorizedUsersAsString) {
      createWhitelist();
    }
  }, [whitelist, authorizedUsersAsString, createWhitelist]);

  useEffect(() => {
    setCreateWhitelistLoadingDialog(!!createWhitelistLoading);
    if (!data) return;
    setCreateWhitelistLoadingDialog(false);
    setOpenedUploadedDialog(true);
  }, [data, createWhitelistLoading]);

  return (
    <>
      <div className={classes.mainWrapper}>
        <Paper className="section">
          <h2 className="title">{t('whitelist.empty.title')}</h2>
          <p className="subtitle">{t('whitelist.empty.subtitle')}</p>
          <Button
            variant="contained"
            color="primary"
            className="addWhitelistButton"
            onClick={() => setOpenedConfirmationDialog(true)}
          >
            <label className="addWhitelistLabel">
              <span>{t('whitelist.empty.updateButton')}</span>
              <BackupIcon className="icon" />
            </label>
          </Button>
          <MuiButton
            color="primary"
            className="downloadButton"
            onClick={() => {
              exportExample(currentCompany.usersCanRegisterWithIdentifier);
            }}
          >
            {t('whitelist.empty.downloadButton')}
          </MuiButton>
          <div className="footer">
            <p>{t('whitelist.empty.example.desc-1')}</p>
            <p>{t('whitelist.empty.example.desc-2')}</p>
          </div>
        </Paper>
      </div>
      <ConfirmDialog
        isOpen={openedConfirmationDialog}
        setWhitelist={setWhitelist}
        setAuthorizedUsersAsString={setAuthorizedUsersAsString}
        handleClose={() => setOpenedConfirmationDialog(false)}
        setAuthorizedUsersCount={setAuthorizedUsersCount}
      />
      {data?.whitelist?.emails?.length && (
        <WhitelistUploadedDialog
          employeeCount={data.whitelist.emails.length}
          isOpen={openedUploadedDialog}
          handleClose={() => {
            fetchData();
            setOpenedUploadedDialog(false);
          }}
        />
      )}
      {createWhitelistLoadingDialog && (
        <WhitelistLoadingDialog
          isOpen={createWhitelistLoadingDialog}
          usersCount={authorizedUsersCount || 0}
        />
      )}
    </>
  );
};

export default NoWhitelist;
