import React, { useState } from 'react';
import { Typography, makeStyles, Paper, Button } from '@material-ui/core';
import { Challenge } from './utils';
import { white, blueGem, alto, neonCarrot, emerald, black } from '../../utils/colors';
import moment from 'moment';
import { LocalCompany, Manager } from '../../@types/common';
import { useTranslate } from 'react-admin';
import DeleteChallengeDialog from './deleteChallengeDialog';

const useStyles = makeStyles({
  container: {
    width: 500,
    backgroundColor: white,
    marginTop: 32,
    borderRadius: 6,
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: 194,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    objectFit: 'cover',
    objectPosition: 'top',
  },
  statusBadge: {
    position: 'absolute',
    top: 24,
    left: 24,
    padding: '5px 20px',
    borderRadius: 200,
  },
  containerNone: {
    backgroundColor: blueGem,
  },
  containerDraft: {
    backgroundColor: alto,
  },
  containerScheduled: {
    backgroundColor: '#FFF5E5',
  },
  containerPublished: {
    backgroundColor: '#C9F2DB',
  },
  textNone: {
    color: white,
  },
  textDraft: {
    color: black,
  },
  textScheduled: {
    color: neonCarrot,
  },
  textPublished: {
    color: emerald,
  },
  challengeDate: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '1.5px',
  },
  infoContainer: {
    padding: '16px',
  },
  description: {
    marginTop: 24,
  },
  cardFooter: {
    marginTop: 16,
  },
  deleteButton: {
    marginTop: 8,
  },
});

interface ChallengeCardProps {
  challenge?: Challenge;
  loading: boolean;
  onClick: () => void;
}

const ChallengeCard = (props: ChallengeCardProps) => {
  const styles = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const currentCompany: LocalCompany = JSON.parse(
    localStorage.getItem('currentCompany') as string
  );
  const t = useTranslate();
  let manager: Manager;
  let content = localStorage.getItem('manager');
  manager = JSON.parse(content!);
  const regex = /@gymlib\.com$/;
  const isGymlibUser = regex.test(manager.email);

  const StatusBadge = ({ status }: { status: Challenge['status'] | 'NONE' }) => {
    let containerClassname: string;
    let textClassName: string;
    let text: string;
    switch (status) {
      case 'DRAFT':
        containerClassname = styles.containerDraft;
        textClassName = styles.textDraft;
        text = t('challenges.card.status.draft');
        break;
      case 'SCHEDULED':
        containerClassname = styles.containerScheduled;
        textClassName = styles.textScheduled;
        text = t('challenges.card.status.scheduled');
        break;
      case 'PUBLISHED':
      case 'STARTED':
        containerClassname = styles.containerPublished;
        textClassName = styles.textPublished;
        text = t('challenges.card.status.started');
        break;
      case 'NONE':
      default:
        containerClassname = styles.containerNone;
        textClassName = styles.textNone;
        text = t('challenges.card.status.none');
    }
    return (
      <div className={`${styles.statusBadge} ${containerClassname}`}>
        <Typography className={textClassName}>{text}</Typography>
      </div>
    );
  };

  return (
    <>
      <div onClick={props.onClick}>
        <Paper className={styles.container}>
          {!props.loading && props.challenge && (
            <>
              <img
                className={styles.image}
                alt=""
                src={
                  props.challenge.picture || require('./assets/challenge-background.png')
                }
              />
              <StatusBadge status={props.challenge ? props.challenge.status : 'NONE'} />
              <div className={styles.infoContainer}>
                <Typography variant="overline" className={styles.challengeDate}>
                  {props.challenge.publishedDate
                    ? t('challenges.card.publishedDate', {
                        publishedDate: moment(props.challenge.publishedDate).format(
                          'DD/MM/YYYY'
                        ),
                      })
                    : t('challenges.card.noPublishedDate')}
                </Typography>
                <Typography variant="h6">
                  {props.challenge
                    ? props.challenge.name
                    : t('challenges.card.title', { companyName: currentCompany.name })}
                </Typography>
                <Typography variant="body1" className={styles.description}>
                  {props.challenge && props.challenge.description
                    ? props.challenge.description
                    : t('challenges.card.description', {
                        companyName: currentCompany.name,
                      })}
                </Typography>
                <div className={styles.cardFooter}>
                  {isGymlibUser && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={props.onClick} // here we should redirect to details
                    >
                      {props.challenge
                        ? props.challenge.status === 'DRAFT'
                          ? t('challenges.card.button.modify')
                          : t('challenges.card.button.open')
                        : t('challenges.card.button.setup')}
                    </Button>
                  )}
                  {isGymlibUser && (
                    <Button
                      color="primary"
                      fullWidth
                      className={styles.deleteButton}
                      onClick={e => {
                        e.stopPropagation();
                        setOpenDeleteModal(true);
                      }}
                    >
                      {t('challenges.card.button.delete')}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </Paper>
      </div>
      {props.challenge && (
        <DeleteChallengeDialog
          challengeID={props.challenge.id}
          displayDialog={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  );
};

export default ChallengeCard;
