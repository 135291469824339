import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  loader: {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #3c0cbf',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: '$spinner 1s linear infinite',
    position: 'absolute',
    top: '40%',
    left: '45%',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const Spinner = () => {
  const styles = useStyles();
  return <div className={styles.loader}></div>;
};

export default Spinner;
