import { LocalCompany, Manager } from '../@types/common';

export const createEvent = (eventName: string, rest?: object) => {
  const currentCompany: LocalCompany | undefined = JSON.parse(
    localStorage.getItem('currentCompany') as string
  );
  const manager: Manager | undefined = JSON.parse(
    localStorage.getItem('manager') as string
  );

  if (currentCompany && manager) {
    gtag('event', eventName, {
      company_name: currentCompany.name,
      company_id: currentCompany.id,
      hr_contact: manager.email,
      ...rest,
    });
  }
};
