import { IResourceExport } from '../../@types/dataProvider';
import UserIcon from '@material-ui/icons/Group';
import synchronizationProvider from './dataProvider';
import { SynchronizationWhitelists } from './synchronizationWhitelist';

export default {
  name: 'Synchronization',
  resources: {
    list: SynchronizationWhitelists,
  },
  icon: UserIcon,
  dataProvider: {
    getOneName: 'synchronization',
    provider: synchronizationProvider,
  },
} as IResourceExport;
