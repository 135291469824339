import React, { Fragment } from 'react';

import { MenuItem, ListItemIcon, useTheme } from '@material-ui/core';

interface MenuItemWithIconProps {
  link: any;
  text: any;
  icon?: any;
  index: any;
  onClick?: () => void;
}

const MenuItemWithIcon = ({
  link,
  text,
  icon,
  index,
  onClick,
}: MenuItemWithIconProps) => {
  const theme = useTheme();
  return (
    <Fragment>
      <MenuItem
        tabIndex={index}
        style={{ color: theme.palette.text.secondary }}
        onClick={() => {
          onClick && onClick();
          window.open(link, '_blank');
        }}
      >
        <ListItemIcon style={{ minWidth: theme.spacing(5) }}>{icon}</ListItemIcon>
        {text}
      </MenuItem>
    </Fragment>
  );
};

export default MenuItemWithIcon;
