import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const DELETE_MUTATION = gql`
  mutation DH_deleteChampionship($input: DH_DeleteChampionshipInput!) {
    data: DH_deleteChampionship(input: $input) {
      championship {
        id
      }
    }
  }
`;

export const Delete = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    const parseResponse = (resp: any) => {
      if (!resp.data && !resp.data.data && !resp.data.data.championship)
        return { data: {} };

      return { data: resp.data.data.championship };
    };

    console.log('[DELETE][CHAMPIONSHIP]', params);

    return {
      ...builtQuery('DELETE', 'Championship', params),
      parseResponse,
      query: DELETE_MUTATION,
      variables: { input: { championshipID: params.id } },
    };
  };
};
