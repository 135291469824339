import challengeProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'ChallengeTemplate',
  dataProvider: {
    getOneName: 'DH_challengeTemplate',
    provider: challengeProvider,
  },
} as IResourceExport;
