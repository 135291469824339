import React, { FunctionComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { amaranth, neonCarrot, creamCan, emerald } from '../utils/colors';

export type SnackbarStatus = 'error' | 'warning' | 'info' | 'success';

const ColorStatusMapping = new Map<SnackbarStatus, string>([
  ['error', amaranth],
  ['warning', neonCarrot],
  ['info', creamCan],
  ['success', emerald],
]);

export interface CustomSnackbarProps extends SnackbarProps {
  status: SnackbarStatus;
}

const Snackbar: FunctionComponent<CustomSnackbarProps> = ({
  status,
  ...snackbarProps
}) => {
  const CustomSnackbar = withStyles({
    root: {
      '& > .MuiSnackbarContent-root': { backgroundColor: ColorStatusMapping.get(status) },
    },
  })(MuiSnackbar);

  return <CustomSnackbar {...snackbarProps} />;
};

export default Snackbar;
