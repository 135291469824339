import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetOne } from './GetOne';
import { Update } from './Update';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne(introspection),
    UPDATE: Update(introspection),
  };
};
