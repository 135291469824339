import React, { useState, useEffect, useCallback } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Typography } from '@material-ui/core';
import { useDataProvider, useTranslate } from 'react-admin';
import CurrentWhitelist from './currentWhitelist';
import OlderWhitelists from './olderWhitelists';
import NoWhitelist from './noWhitelist';
import { useSelector, useDispatch } from 'react-redux';
import { updateWhitelistPaginatedAction } from '../../redux/actions';
import { SynchronizationWhitelists } from '../synchronization/synchronizationWhitelist';
import { createEvent } from '../../utils/analytics';
import CurrentWhitelistWithRefKeys from './currentWhitelistWithRefKeys';

const useStyles = makeStyles({
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  pageTitle: {
    marginBottom: '10px',
  },
  container: {
    padding: 32,
  },
});

const WhitelistTabs = () => {
  const styles = useStyles();
  const t = useTranslate();
  const [tabValue, setTabValue] = useState(0);
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(true);
  const whitelist = useSelector(
    (state: any) => state.whitelistPaginatedReducers.whitelist
  );
  const company = useSelector((state: any) => state.whitelistPaginatedReducers.company);
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.whitelistPaginatedReducers.search);
  const integrationType = useSelector(
    (state: any) => state.whitelistPaginatedReducers.integrationType
  );

  const fetchData = useCallback(
    (
      page: number = 0,
      perPage: number = 10,
      search?: string,
      integrationType?: string,
      responseAsString?: boolean
    ) => {
      const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
      dataProvider
        .getOne('CorporateCompany', {
          id: currentCompany.id,
          page,
          perPage,
          search,
          integrationType,
          responseAsString: !!currentCompany?.usersCanRegisterWithIdentifier,
        })
        .then(({ data, company, total }: { data: any; company: any; total: number }) => {
          dispatch(
            updateWhitelistPaginatedAction(
              data,
              company,
              total,
              search,
              integrationType,
              responseAsString
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dataProvider, dispatch]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const _pressCurrentListTab = () => {
    createEvent('actual_whitelist_pressed');
    setTabValue(0);
  };
  const _pressOlderListTab = () => {
    createEvent('past_whitelists_pressed');
    setTabValue(1);
  };

  const _pressSyncUsersListTab = () => {
    createEvent('automatic_sync_pressed');
    setTabValue(2);
  };

  if (isLoading) return null;

  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
  return (
    <div style={{ padding: '32px' }}>
      <Typography className={styles.pageTitle} variant="h4">
        {t('resources.Whitelist.name')}
      </Typography>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
      >
        <Tab label={t('whitelist.tabs.currentTitle')} onClick={_pressCurrentListTab} />
        <Tab label={t('whitelist.tabs.olderTitle')} onClick={_pressOlderListTab} />
        <Tab
          label={t('whitelist.tabs.synchronization')}
          onClick={_pressSyncUsersListTab}
        />
      </Tabs>
      {tabValue === 0 && !integrationType && !search && !whitelist.length && (
        <NoWhitelist fetchData={fetchData} />
      )}

      {tabValue === 0 &&
        (integrationType || search || whitelist.length > 0) &&
        (currentCompany?.usersCanRegisterWithIdentifier ? (
          <CurrentWhitelistWithRefKeys
            whitelist={whitelist}
            company={company}
            fetchData={fetchData}
          />
        ) : (
          <CurrentWhitelist
            whitelist={whitelist}
            company={company}
            fetchData={fetchData}
          />
        ))}
      {tabValue === 1 && <OlderWhitelists />}
      {tabValue === 2 && <SynchronizationWhitelists />}
    </div>
  );
};

export default WhitelistTabs;
