import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import ChampionshipDetails from './championshipDetails';
import { AddChampionship } from './addChampionship';
import Snackbar, { SnackbarStatus } from '../../components/Snackbar';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteChampionshipDialog from './dialogs/DeleteChampionshipDialog';
import { IChampionship } from './utils';
import AddChampionshipDialog from './dialogs/addChampionshipDialog';
import { parse as convertFromCSV } from 'papaparse';

const AUTOHIDE_UNDO_SNACKBAR = 4000;

export const Championships = ({
  championships,
  page,
  perPage,
  setPage,
  setPerPage,
  setAskPreviousPage,
  challengeStatus,
  challengeID,
  styles,
  refresh,
}: {
  championships: any;
  page: any;
  perPage: any;
  setPage: any;
  setPerPage: any;
  setAskPreviousPage: any;
  challengeStatus: string;
  challengeID: string;
  styles: any;
  refresh: any;
}) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const [championshipsSelected, setChampionshipsSelected] = useState<IChampionship[]>([]);
  const [snackbar, setSnackbar] = useState<{
    status: SnackbarStatus;
    open: boolean;
    message: string;
  }>({ status: 'success', open: false, message: '' });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [championshipsToAdd, setChampionshipsToAdd] = useState<string[]>([]);
  const handleChampionshipSelected = useCallback(
    (isChecked: boolean, value: IChampionship) => {
      setChampionshipsSelected(oldValue => {
        if (isChecked) {
          oldValue.push(value);
          return [...oldValue];
        } else {
          return [...oldValue].filter(x => x.id !== value.id);
        }
      });
    },
    [setChampionshipsSelected]
  );

  const handleOnSuccessDelete = () => {
    refresh();
    setChampionshipsSelected([]);
    setOpenDeleteDialog(false);
    setSnackbar({
      status: 'success',
      open: true,
      message: t('championships.list.deleteDialog.snackbarSuccess', {
        smart_count: championshipsSelected.length,
      }),
    });
  };

  const uploadCSV = (e: ChangeEvent<HTMLInputElement>) => {
    convertFromCSV(e!.target!.files![0], {
      skipEmptyLines: true,
      complete: (results: any) => {
        if (!results.data) {
          setSnackbar({
            status: 'error',
            open: true,
            message: t('companyOffice.list.header.cannotAddFile'),
          });
        } else {
          const filteredChampionships = (results.data as string[][])
            .filter(line => {
              if (line.length > 1) return false;
              if (
                // we try not to add the header if it appears
                line[0] &&
                ['name', 'namekey'].includes(line[0].toLowerCase())
              )
                return false;
              return true;
            })
            .reduce(function(acc, cur) {
              if (acc.findIndex(elem => elem.toLowerCase() === cur[0].toLowerCase()) < 0)
                acc.push(cur[0]);
              return acc;
            }, [] as string[]);
          if (filteredChampionships.length === 0) {
            setSnackbar({
              status: 'error',
              open: true,
              message: t('championships.list.header.noChampionshipsDetected'),
            });
          } else {
            setChampionshipsToAdd(filteredChampionships);
            setOpenAddDialog(true);
          }
        }
      },
    });
    // Reset the input file to trigger onChange if same file is given
    e.target.value = '';
  };

  const handleUploadChampionships = async () => {
    const promises = championshipsToAdd.map(async championship =>
      dataProvider.create('Championship', {
        input: {
          challengeID,
          nameKey: championship,
        },
      })
    );
    try {
      await Promise.all(promises);
      refresh();
      setSnackbar({
        status: 'success',
        message: 'Done',
        open: true,
      });
    } catch (err) {
      setSnackbar({
        status: 'error',
        message: 'Failed',
        open: true,
      });
    }
    setOpenAddDialog(false);
  };

  if (!championships) return null;

  return (
    <div className={styles.championshipsContainer}>
      <div className={styles.championshipInfos}>
        <h2 className={styles.subtitle}>{t('challenges.details.title')}</h2>
        <div className={styles.championshipsDescription}>
          {t('challenges.details.description')}
        </div>
      </div>
      <Table>
        <TableHead>
          {championshipsSelected.length > 0 && (
            <TableRow className={styles.rowDeleteChampionshipInfo}>
              <TableCell>Selected groups: {championshipsSelected.length}</TableCell>
              <TableCell align="right">
                <IconButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  className="icon"
                >
                  <DeleteIcon onClick={() => setOpenDeleteDialog(true)} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={styles.headerChampionshipName}>
              {t('challenges.details.tableCell.championshipNames')}
            </TableCell>
            <TableCell align="right">
              {t('challenges.details.tableCell.userCount')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {championships.edges &&
            championships.edges.map(({ cursor, node }: { cursor: any; node: any }) => (
              <ChampionshipDetails
                key={cursor}
                node={node}
                translate={t}
                challengeStatus={challengeStatus}
                handleChampionshipSelected={handleChampionshipSelected}
              />
            ))}
          <AddChampionship
            challengeID={challengeID}
            refresh={refresh}
            uploadCSV={uploadCSV}
          ></AddChampionship>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[15]}
              page={page}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => {
                setAskPreviousPage(page > newPage);
                setPage(newPage);
              }}
              onRowsPerPageChange={e => setPerPage(parseInt(e!.target!.value))}
              count={championships.totalCount}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteChampionshipDialog
        championshipsToDelete={championshipsSelected}
        hideDialog={() => setOpenDeleteDialog(false)}
        openDialog={openDeleteDialog}
        setSnackbar={setSnackbar}
        total={championships.totalCount}
        onSuccess={handleOnSuccessDelete}
      ></DeleteChampionshipDialog>
      <AddChampionshipDialog
        openDialog={openAddDialog}
        onCloseDialog={() => setOpenAddDialog(false)}
        setSnackbar={setSnackbar}
        championshipsToAdd={championshipsToAdd}
        handleUploadChampionships={handleUploadChampionships}
      ></AddChampionshipDialog>
      <Snackbar
        status={snackbar.status}
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={AUTOHIDE_UNDO_SNACKBAR}
        onClose={() => setSnackbar({ open: false, message: '', status: 'success' })}
      />
    </div>
  );
};
