import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const GET_ONE_QUERY = gql`
  query DH_challenge(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $loadChampionships: Boolean!
  ) {
    data: DH_challenge(id: $id) {
      id
      name
      status
      publishedDate
      picture
      championships(first: $first, after: $after, last: $last, before: $before)
        @include(if: $loadChampionships) {
        edges {
          cursor
          node {
            id
            name
            channel {
              id
              userCount
            }
            teams {
              edges {
                cursor
                node {
                  id
                  name
                  channel {
                    id
                    userCount
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`;

export const GetOne = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_ONE', 'Challenge', params),
      variables: params,
      // Override the query
      query: GET_ONE_QUERY,
      parseResponse: (resp: any) => {
        if (!resp.data) return { data: {} };
        return { data: resp.data.data };
      },
    };
  };
};
