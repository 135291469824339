import { IResourceExport } from '../../@types/dataProvider';
import UserIcon from '@material-ui/icons/Group';

import WhitelistGoogleWorkSpaceProvider from './dataProvider';
import WhitelistTabs from './whitelistTabs';

export default {
  name: 'WhitelistGoogleWorkSpace',
  resources: {
    list: WhitelistTabs,
  },
  icon: UserIcon,
  dataProvider: {
    getOneName: 'whitelistGoogleWorkSpace',
    provider: WhitelistGoogleWorkSpaceProvider,
  },
} as IResourceExport;
