import React from 'react';
import { useTranslate } from 'react-admin';
import { blueGem, scarpaFlow } from '../../utils/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../../components/Spinner';

const ListEmails = ({ synchronizationData, isLoading }: any) => {
  const t = useTranslate();

  return (
    <div>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {t('whitelist.current.table.email.label')}
                </TableCell>
                <TableCell align="center">
                  {t('whitelist.current.table.organizationalUnits.label')}
                </TableCell>
                <TableCell align="right">
                  {t('whitelist.current.table.ignoredUser.label')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {synchronizationData &&
                synchronizationData.users.map((e: any) => (
                  <TableRow key={e.email}>
                    <TableCell align="left">{e.email}</TableCell>
                    <TableCell align="center">{e.organizationalUnit}</TableCell>
                    <TableCell align="right">
                      {e.ignoredUser ? (
                        <span style={{ color: scarpaFlow }}>
                          {t('value.ignoredUser.exclus')}
                        </span>
                      ) : (
                        <span style={{ color: blueGem }}>
                          {t('value.ignoredUser.ready')}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ListEmails;
