import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { LocalCompany } from '../../@types/common';
import { ExternalIntegrationStatus } from './synchronizationWhitelist';
import Spinner from '../../components/Spinner';

const useStyles = makeStyles({
  titleSynchronization: {
    marginTop: '2em',
    marginBottom: '1em',
    textAlign: 'center',
  },
  p: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
  },
  spinner: {
    position: 'relative',
    marginBottom: '70px',
  },
});

interface ExternalIntegrationPendingProps {
  setStatusExternalIntegration: any;
  isLoading: any;
  synchronizationData: any;
}

const ExternalIntegrationPending = (props: ExternalIntegrationPendingProps) => {
  const t = useTranslate();
  const styles = useStyles();
  const dataProvider = useDataProvider();
  const { setStatusExternalIntegration, isLoading, synchronizationData } = props;

  const handleClickAnnulation = () => {
    const currentCompany: LocalCompany = JSON.parse(localStorage.getItem(
      'currentCompany'
    ) as string);

    dataProvider
      .delete('Synchronization', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        if (data) {
          setStatusExternalIntegration(null);
        }
      });
  };

  const handleClick = () => {
    const currentCompany: LocalCompany = JSON.parse(localStorage.getItem(
      'currentCompany'
    ) as string);

    dataProvider
      .update('Synchronization', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        if (data) {
          setStatusExternalIntegration(ExternalIntegrationStatus.ACTIVE);
        }
      });
  };

  return (
    <div>
      <h4 className={styles.titleSynchronization}>{t('google.workspace.title3')}</h4>
      <p className={styles.p}>{t('google.workspace.title4')}</p>
      <p className={styles.p}>
        {synchronizationData &&
          synchronizationData.users.filter((user: any) => user.ignoredUser === false)
            .length}
        &nbsp;
        {t('google.workspace.title6')}
      </p>

      <div className={styles.spinner}>{isLoading && <Spinner />}</div>
      {!isLoading && (
        <>
          <div className={styles.button}>
            <Button color="primary" onClick={handleClickAnnulation}>
              {t('google.workspace.button.6')}
            </Button>
            {synchronizationData &&
              synchronizationData.users.filter((user: any) => user.ignoredUser === false)
                .length > 0 && (
                <Button variant="contained" color="primary" onClick={handleClick}>
                  {t('google.workspace.button.5')}
                </Button>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExternalIntegrationPending;
