import React, { FunctionComponent, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslate, useMutation } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { shark, blueGem } from '../../utils/colors';
import MuiButton from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { updateChallengeAction } from '../../redux/actions';

const useStyles = makeStyles({
  container: {
    padding: '50px 64px',
    color: shark,
    position: 'relative',
    maxWidth: '669px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: '14px',
    right: '14px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  bodyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    margin: '70px 0 0 0',
    color: blueGem,
    fontSize: '24px',
    maxWidth: '384px',
    textAlign: 'center',
    marginBottom: '4px',
  },
  description: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
  },
  datePicker: {
    width: '328px',
    margin: '10px 0 55px 0',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& >:first-child': {
      marginRight: '40px',
    },
  },
});

interface PublishChallengeDialogProps {
  handleClose: () => void;
  displayDialog: boolean;
  challenge: any;
  setSnackbar: (snackbarInfos: {
    status: string;
    message: string;
    open: boolean;
  }) => void;
}

const PublishChallengeDialog: FunctionComponent<PublishChallengeDialogProps> = ({
  displayDialog,
  handleClose,
  challenge,
  setSnackbar,
}) => {
  const nextMonday =
    moment().isoWeekday() > 1
      ? moment()
          .add(1, 'week')
          .isoWeekday(1)
          .startOf('day')
      : moment()
          .isoWeekday(1)
          .startOf('day');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    challenge.publishedDate ? moment(challenge.publishedDate) : nextMonday
  );
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();

  const [publish, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'Challenge',
      payload: {
        data: {
          input: {
            id: challenge.id,
            publishedDate: selectedDate ? selectedDate.toISOString() : null,
          },
        },
      },
    },
    {
      onSuccess: ({ data }: { data: any }) => {
        dispatch(updateChallengeAction(data));
        setSnackbar({ open: true, message: 'Challenge published', status: 'success' });
        handleClose();
      },
    }
  );

  return (
    <Dialog open={displayDialog} onClose={handleClose} maxWidth="md">
      <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.bodyContainer}>
          <h1 className={classes.title}>{t('challenge.publishDialog.title')}</h1>
          <p className={classes.description}>
            {t('challenge.publishDialog.description')}
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.datePicker}
              placeholder={t('challenge.publishDialog.datePickerPlaceholder')}
              disablePast
              format="DD/MM/YY"
              shouldDisableDate={day => Boolean(day && moment(day).isoWeekday() !== 1)}
              value={selectedDate}
              onChange={setSelectedDate}
            />
          </MuiPickersUtilsProvider>
          <div className={classes.footerContainer}>
            <MuiButton color="primary" onClick={handleClose} disabled={loading}>
              {t('challenge.publishDialog.cancelButton')}
            </MuiButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={publish}
              disabled={loading || !selectedDate}
            >
              {t('challenge.publishDialog.publishButton')}
            </MuiButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PublishChallengeDialog;
