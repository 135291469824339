import gql from 'graphql-tag';

const UPDATE_EXTERNAL_INTEGRATION = gql`
  mutation ActiveExternalIntegrationGoogle($id: ID!) {
    ActiveExternalIntegrationGoogle(id: $id) {
      data: id
    }
  }
`;

export const Update = (params: any) => {
  return {
    query: UPDATE_EXTERNAL_INTEGRATION,
    variables: { id: params.id },
    parseResponse: (resp: any) => {
      return { data: { ...resp } };
    },
  };
};
