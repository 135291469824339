import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetOne } from './GetOne';
import { Update } from './Update';
import { GetList } from './Many';
import { Delete } from './Delete';
import { Create } from './Create';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne(introspection),
    GET_LIST: GetList(),
    GET_MANY: GetList(),
    UPDATE: Update(introspection),
    DELETE: Delete(introspection),
    CREATE: Create(),
  };
};
