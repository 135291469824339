import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation DH_createChallenge($input: DH_CreateChallengeInput!) {
    data: DH_createChallenge(input: $input) {
      challenge {
        id
        name
        status
        publishedDate
        championships {
          edges {
            cursor
            node {
              id
              name
              channel {
                id
                userCount
              }
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const Create = () => {
  return (params: any) => {
    console.log('[CREATE][CHALLENGE]', params);
    return {
      query: CREATE_MUTATION,
      variables: params,
      parseResponse: (resp: any) => {
        if (resp.data.data.challenge.id) {
          return { data: resp.data.data.challenge };
        }
      },
    };
  };
};
