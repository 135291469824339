import React, { useState, useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import { Provider } from 'react-redux';

import gymlibTheme from './gymlibTheme';
import authProvider from './authProvider/authProvider';
import reloadedDataProvider from './dataProvider/dataProvider';

import resources from './resources';

import addUploadFeature from './UploadFeature';

import { Layout } from './layout';

import { Login } from './layout/login';

import { Dashboard } from './dashboard';

import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import gymlibMessages from './i18n';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customRoutes from './routes';
import createAdminStore from './createAdminStore';
import { createHashHistory } from 'history';

const messages: any = {
  fr: { ...frenchMessages, ...gymlibMessages.fr },
  en: { ...englishMessages, ...gymlibMessages.en },
  nl: { ...dutchMessages, ...gymlibMessages.nl },
};

const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages['fr']),
  resolveBrowserLocale()
);

const history = createHashHistory();

const App = () => {
  const [dataProvider, setDataProvider] = useState<
    (() => (type: any, resource: any, params: any) => Promise<any> | null) | null
  >(null);

  useEffect(() => {
    reloadedDataProvider(resources).then((graphQlDataProvider: Function) => {
      setDataProvider(() => addUploadFeature(graphQlDataProvider));
    });
  }, []);

  if (!dataProvider) return <div>{i18nProvider.translate('loading')}</div>;

  return (
    <Provider
      store={createAdminStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <Admin
        dataProvider={dataProvider}
        customRoutes={customRoutes}
        authProvider={authProvider}
        history={history}
        loginPage={Login}
        dashboard={Dashboard}
        theme={gymlibTheme}
        i18nProvider={i18nProvider}
        layout={Layout}
      >
        {Object.values(resources).map(resource => {
          if (resource.resources) {
            return (
              <Resource
                key={resource.name}
                name={resource.name}
                {...resource.resources}
              />
            );
          } else {
            // fake resources
            return <Resource key={resource.name} name={resource.name} />;
          }
        })}
      </Admin>
    </Provider>
  );
};

export default App;
