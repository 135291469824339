import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DefaultTheme } from '@material-ui/styles/defaultTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { useMutation, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { updateWhitelistAction } from '../../redux/actions';
import { emerald, amaranth, frenchGray, shark, white } from '../../utils/colors';
import { Button } from '../../components';

const useWrapperStyles = makeStyles<DefaultTheme, { isAddition: boolean }>({
  container: { padding: '0 64px', color: shark, maxWidth: '669px' },
  list: {
    maxHeight: '100px',
    overflowY: 'scroll',
    '& >p': { fontSize: '14px', margin: '10px 0' },
  },
  summary: {
    color: props => (props.isAddition ? emerald : amaranth),
    fontSize: '14px',
    margin: '5px 0',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& >.delIcon': { color: amaranth },
    '& >.addIcon': { color: emerald },
    '& >.title': { marginLeft: '10px', fontSize: '18px' },
  },
  onlyDeletionWrapper: {
    padding: '0 64px',
    color: shark,
    boxSizing: 'border-box',
    width: '669px',
    textAlign: 'center',
    '& > .iconWrapper': {
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '48px',
      height: '48px',
      background: amaranth,
      borderRadius: '4px',
      marginBottom: '16px',
      '& >.icon': {
        color: white,
        fontSize: '24px',
      },
    },
    '& > .summary': {
      color: amaranth,
      fontWeight: 500,
      fontSize: '24px',
      marginBottom: '16px',
    },
  },
});

const useStyles = makeStyles({
  close: { position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' },
  noDiffLabel: { margin: '0 20px' },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > :first-child': { margin: '0 0 5px 0' },

    '& >button': {
      margin: '5px 0',
      borderRadius: '20px',
    },
  },
  container: {
    padding: '48px 0 24px 0',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    marginBottom: '32px',
  },
  summaryDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '42px 76px',

    '& >.icon': {
      color: emerald,
      marginBottom: '20px',
      fontSize: '40px',
    },

    '& .text': {
      textAlign: 'center',
      margin: 0,
    },

    '& > :last-child': {
      width: '100%',

      '& .confirmButton': {
        fontSize: '14px',
        marginTop: '30px',
        borderRadius: '20px',
        width: '100%',
      },
    },
  },
});

const getDifferences = ({
  currentWhitelist,
  newWhitelist,
}: {
  currentWhitelist: string[];
  newWhitelist: string[];
}) => ({
  additions: [
    ...new Set(newWhitelist.filter(email => email && !currentWhitelist.includes(email))),
  ],
  deletions: [
    ...new Set(currentWhitelist.filter(email => email && !newWhitelist.includes(email))),
  ],
});

interface EnhancedAdditionWrapperProps {
  addedEmails: string[];
}

const EnhancedAdditionWrapper = (props: EnhancedAdditionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: true });
  const t = useTranslate();
  const { addedEmails } = props;

  return (
    <DialogContentText>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <CheckCircleIcon className="addIcon" />
          <span className="title">
            {t('whitelist.updateDialog.addedEmails.title', {
              smart_count: addedEmails.length,
            })}
          </span>
        </div>
        <p className={classes.summary}>
          {t('whitelist.updateDialog.addedEmails.text', {
            smart_count: addedEmails.length,
          })}
        </p>
        <div className={classes.list}>
          {addedEmails.map((email: string) => (
            <p key={email}>{email}</p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

interface EnhancedDeletionWrapperProps {
  deletedEmails: string[];
}

const EnhancedDeletionWrapper = (props: EnhancedDeletionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: false });
  const t = useTranslate();
  const { deletedEmails } = props;

  return (
    <DialogContentText>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <CancelIcon className="delIcon" />
          <span className="title">
            {t('whitelist.updateDialog.deletedEmails.title', {
              smart_count: deletedEmails.length,
            })}
          </span>
        </div>
        <p className={classes.summary}>
          {t('whitelist.updateDialog.deletedEmails.text', {
            smart_count: deletedEmails.length,
          })}
        </p>
        <div className={classes.list}>
          {deletedEmails.map((email: string) => (
            <p key={email}>{email}</p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

const EnhancedOnlyDeletionWrapper = (props: EnhancedDeletionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: false });
  const t = useTranslate();
  const { deletedEmails } = props;

  return (
    <DialogContentText>
      <div className={classes.onlyDeletionWrapper}>
        <div className="iconWrapper">
          <InfoOutlinedIcon className="icon" />
        </div>
        <p className="summary">
          {t('whitelist.updateDialog.onlyDeletedEmails.text', {
            smart_count: deletedEmails.length,
          })}
        </p>
        <div className={classes.list}>
          {deletedEmails.map((email: string) => (
            <p key={email}>{email}</p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

const Separator = () => {
  return <div style={{ background: frenchGray, height: '1px', margin: '20px 0' }} />;
};

const EmailsDetails: FunctionComponent<{
  addedEmails: string[];
  deletedEmails: string[];
}> = ({ addedEmails, deletedEmails }) => {
  const classes = useStyles();
  const t = useTranslate();

  if (addedEmails.length)
    return (
      <>
        <EnhancedAdditionWrapper addedEmails={addedEmails} />
        <Separator />
        {Boolean(deletedEmails.length) && (
          <EnhancedDeletionWrapper deletedEmails={deletedEmails} />
        )}
      </>
    );
  else if (deletedEmails.length)
    return (
      <>
        <EnhancedOnlyDeletionWrapper deletedEmails={deletedEmails} />
        <Separator />
      </>
    );
  else
    return (
      <span className={classes.noDiffLabel}>
        {t('whitelist.updateDialog.noDifferences.desc')}
      </span>
    );
};

const UpdateWhitelistDialog = ({
  companyID,
  displayDialog,
  newWhitelist,
  handleClose,
  setSelected,
}: {
  companyID: string;
  displayDialog: boolean;
  handleClose: () => void;
  newWhitelist: string[];
  setSelected: any;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [addedEmails, setAddedEmails] = useState([] as string[]);
  const [deletedEmails, setDeletedEmails] = useState([] as string[]);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [understood, setUnderstood] = useState(false);

  const [createWhitelist, { data, loading }] = useMutation({
    type: 'create',
    resource: 'Whitelist',
    payload: { data: { input: { companyID, emails: newWhitelist } } },
  });

  const currentWhitelist = useSelector((state: any) => state.whitelistReducers.whitelist);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data || !data.whitelist) return;
    dispatch(updateWhitelistAction(data.whitelist.emails));
    setUploadFinished(true);
    setSelected([]);
  }, [data, dispatch, setSelected]);

  useEffect(() => {
    if (!newWhitelist) return;
    const { additions, deletions } = getDifferences({ currentWhitelist, newWhitelist });
    setAddedEmails(additions);
    setDeletedEmails(deletions);
  }, [currentWhitelist, newWhitelist]);

  useEffect(() => {
    if (!displayDialog) {
      if (uploadFinished) setUploadFinished(false);
      setUnderstood(false);

      if (!uploadFinished) {
        dispatch(updateWhitelistAction(currentWhitelist));
      }
    }
  }, [displayDialog, dispatch, currentWhitelist, uploadFinished]);

  const UploadSection = () => (
    <>
      {Boolean(!deletedEmails.length || addedEmails.length) && (
        <DialogTitle className={classes.title}>
          {t('whitelist.updateDialog.title')}
        </DialogTitle>
      )}
      <CloseIcon className={classes.close} onClick={handleClose} />
      <DialogContent>
        <EmailsDetails addedEmails={addedEmails} deletedEmails={deletedEmails} />
      </DialogContent>
      <DialogActions disableSpacing className={classes.actionsWrapper}>
        <FormControlLabel
          style={{ letterSpacing: '0.5px' }}
          control={
            <Checkbox
              style={{ marginRight: '10px' }}
              color="primary"
              checked={understood}
              onChange={() => setUnderstood(!understood)}
            />
          }
          label={t('whitelist.updateDialog.buttons.checkbox')}
        />
        <Button
          variant="contained"
          onClick={() => createWhitelist()}
          color="primary"
          className="confirmButton"
          disabled={!understood || loading}
        >
          {t('whitelist.updateDialog.buttons.confirm')}
        </Button>
        <MuiButton onClick={handleClose} color="primary" disabled={loading}>
          {t('whitelist.updateDialog.buttons.cancel')}
        </MuiButton>
      </DialogActions>
    </>
  );

  const SummarySection = () => (
    <div className={classes.summaryDialog}>
      <CheckCircleIcon fontSize="inherit" className="icon" />
      <DialogContent>
        <p className="text">{t('whitelist.updateDialog.summary.title')}</p>
        <p className="text">
          {t('whitelist.updateDialog.summary.text', {
            smart_count: currentWhitelist.length,
          })}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          className="confirmButton"
          color="primary"
        >
          {t('whitelist.updateDialog.summary.button')}
        </Button>
      </DialogActions>
    </div>
  );

  if (!newWhitelist) return null;

  return (
    <Dialog
      open={displayDialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <div className={classes.container}>
        {!uploadFinished ? <UploadSection /> : <SummarySection />}
      </div>
    </Dialog>
  );
};

export default UpdateWhitelistDialog;
