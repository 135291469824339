import { ACTION_TYPES } from './types';

export function updateWhitelistAction(whitelist: any[]) {
  return {
    type: ACTION_TYPES.UPDATE_WHITELIST,
    payload: { whitelist },
  };
}

export function addWhitelistAction(authorizedUser: {
  email: string;
  source: string;
  id: any;
}) {
  return {
    type: ACTION_TYPES.ADD_EMAIL_WHITELIST,
    payload: { authorizedUser },
  };
}

export function addAuthorizedUserWhitelistAction(authorizedUser: {
  email: string;
  refKey: string;
  source: string;
  id: any;
}) {
  return {
    type: ACTION_TYPES.ADD_AUTHORIZED_USER_WHITELIST,
    payload: { authorizedUser },
  };
}

export function updateWhitelistPaginatedAction(
  whitelist: Array<{ [key: string]: any }>,
  company: { [key: string]: any },
  total: number,
  search?: string,
  integrationType?: string,
  responseAsString?: boolean
) {
  return {
    type: ACTION_TYPES.UPDATE_WHITELIST_PAGINATED,
    payload: { whitelist, company, total, search, integrationType, responseAsString },
  };
}

export function updateChallengeAction(challenge: any) {
  return { type: ACTION_TYPES.UPDATE_CHALLENGE, payload: { challenge } };
}

export function deleteChallengeAction(challengeID: any) {
  return { type: ACTION_TYPES.DELETE_CHALLENGE, payload: { challengeID } };
}
