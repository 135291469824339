import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { emerald } from '../../utils/colors';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { LocalCompany } from '../../@types/common';

const useStyles = makeStyles({
  p: {
    color: emerald,
  },
  button: {
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const customTheme = createTheme({
  palette: {
    secondary: {
      // works
      main: '#fff',
      contrastText: '#808080',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

interface ExternalIntegrationActiveProps {
  setStatusExternalIntegration: any;
}

const ExternalIntegrationActive = (props: ExternalIntegrationActiveProps) => {
  const t = useTranslate();
  const styles = useStyles();
  const dataProvider = useDataProvider();
  const { setStatusExternalIntegration } = props;

  const handleClickDesynchronizationGoogleWorkSpace = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    dataProvider.delete('Synchronization', {
      id: currentCompany.id,
    });
    setStatusExternalIntegration(null);
  };

  return (
    <div>
      <div>
        <div className={styles.center}>
          <p className={styles.p}>{t('google.workspace.p3')}</p>
        </div>
        <div className={styles.center}>
          <ThemeProvider theme={customTheme}>
            <Button
              className={styles.button}
              variant="contained"
              onClick={handleClickDesynchronizationGoogleWorkSpace}
              color="secondary"
              size="small"
            >
              {t('google.workspace.button.1')}
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ExternalIntegrationActive;
