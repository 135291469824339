import passwordResetProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'PasswordReset',
  dataProvider: {
    provider: passwordResetProvider,
    getOneName: 'passwordReset',
  },
} as IResourceExport;
