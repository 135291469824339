import { withStyles, Button as DefaultButton } from '@material-ui/core';
import { blueGem, white } from '../utils/colors';

const Button = withStyles({
  root: {
    borderRadius: '200px',
    padding: '5px 16px',
    background: blueGem,
    color: white,
    fontWeight: 500,
    letterSpacing: ' 0.75px',
    textTransform: 'uppercase',
    '&:hover': {
      background: blueGem,
    },
  },
})(DefaultButton);

export default Button;
