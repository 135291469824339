import React, { useCallback, useEffect, useState } from 'react';
import {
  useGetList,
  useGetOne,
  useTranslate,
  useMutation,
  useRedirect,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import ChallengeCard from './challengeCard';
import TemplateCard from './templateCard';
import { LocalCompany } from '../../@types/common';
import CannotUpdateChallengeDialog from './cannotUpdateChallengeDialog';
import { useHistory } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import DiscoverChallengeDialog from './discoverChallengeDialog';
import { scarpaFlow } from '../../utils/colors';
import { ChallengeTemplate } from './utils';
import { updateChallengeAction } from '../../redux/actions';
import { isInternalEmail } from '../../utils';

const useStyles = makeStyles({
  container: {
    padding: 32,
  },
  pageTitle: {
    marginBottom: '10px',
  },
  pageSubtile: {
    textTransform: 'uppercase',
    color: scarpaFlow,
    fontSize: '14px',
    letterSpacing: '1.25px',
    marginRight: '8px',
  },
});

const Home = () => {
  const styles = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const [templateID, setTemplateID] = useState<string>();
  const [displayDiscoverChallengeDialog, setDisplayDiscoverChallengeDialog] = useState(
    false
  );
  const [
    displayCannotUpdateChallengeDialog,
    setDisplayCannotUpdateChallengeDialog,
  ] = useState(false);

  useEffect(() => {
    if (!isInternalEmail()) {
      redirect('/');
    }
  }, [redirect]);

  const currentCompany: LocalCompany = JSON.parse(
    localStorage.getItem('currentCompany') as string
  );

  const { data: templatesData, loading: templatesLoading } = useGetList(
    'ChallengeTemplate',
    { page: 1, perPage: 20 },
    { field: 'id', order: 'DESC' }
  );

  const {
    data: challengeData,
    ids: challengeIDs,
    loading: challengeLoading,
  } = useGetList(
    'Challenge',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'DESC' },
    { isDone: false }
  );

  const { data: corporateData, loading: corporateLoading } = useGetOne(
    'CorporateCompany',
    currentCompany.id
  );

  const [createChallenge] = useMutation(
    {
      type: 'create',
      resource: 'Challenge',
      payload: {
        input: {
          companyID: currentCompany.id,
          templateID: templateID,
          isFromOffice: true,
        },
      },
    },
    {
      onSuccess: (challenge: any) => {
        history.push(`/Challenge/${challenge.data.id}`);
      },
    }
  );

  const onTemplateButtonClick = useCallback(
    (template: ChallengeTemplate) => {
      if (template.id !== templateID) {
        setTemplateID(template.id);
      }
    },
    [templateID]
  );

  useEffect(() => {
    if (challengeData && challengeIDs && !challengeLoading) {
      dispatch(updateChallengeAction(challengeData[challengeIDs[0]]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeIDs[0], challengeLoading]);

  useEffect(() => {
    if (templateID) {
      createChallenge();
    }
  }, [templateID, createChallenge]);

  const activeChallenge = useSelector(
    (state: any) => state.challengeReducers.activeChallenge
  );

  const onChallengeCardClick = () => {
    if (challengeIDs.length === 0) {
      if (!corporateData.isSocialOnboarded) setDisplayCannotUpdateChallengeDialog(true);
    } else history.push(`/Challenge/${challengeData[challengeIDs[0]].id}`);
  };

  return (
    <div className={styles.container}>
      {activeChallenge ? (
        <>
          <Typography className={styles.pageTitle} variant="h4">
            {t('challenges.list.activeChallenge.title')}
          </Typography>
          <span className={styles.pageSubtile}>
            {t('challenges.list.activeChallenge.subtitle')}
          </span>
          <ChallengeCard
            loading={templatesLoading || challengeLoading || corporateLoading}
            onClick={onChallengeCardClick}
            challenge={activeChallenge}
          />
        </>
      ) : (
        <>
          <Typography className={styles.pageTitle} variant="h4">
            {t('challenges.list.challengeTemplates.title')}
          </Typography>
          <span className={styles.pageSubtile}>
            {t('challenges.list.challengeTemplates.subtitle')}
          </span>
          <MuiButton
            color="primary"
            onClick={() => setDisplayDiscoverChallengeDialog(true)}
          >
            {t('challenges.list.discoverButton')}
          </MuiButton>
          <div>
            {Object.values(templatesData as Record<string, ChallengeTemplate>).map(
              template => (
                <TemplateCard
                  challengeTemplate={template}
                  key={template.id}
                  onClick={() => onTemplateButtonClick(template)}
                />
              )
            )}
          </div>
        </>
      )}
      <CannotUpdateChallengeDialog
        displayDialog={displayCannotUpdateChallengeDialog}
        handleClose={() => setDisplayCannotUpdateChallengeDialog(false)}
        onClick={() => history.push('/Challenge')}
      />
      <DiscoverChallengeDialog
        displayDialog={displayDiscoverChallengeDialog}
        handleClose={() => setDisplayDiscoverChallengeDialog(false)}
      />
    </div>
  );
};

export default Home;
