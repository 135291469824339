import {
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AppBar, translate } from 'react-admin';
import { LocalCompany, Manager } from '../@types/common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserMenu from './UserMenu';

const styles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    transform: 'translate(-50%, 0)',
  },
});

function CompanyChooser() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<null | LocalCompany>(null);

  const [companies, setCompanies] = useState<LocalCompany[]>([]);

  useEffect(() => {
    let manager: Manager;
    let currentCompany: LocalCompany;
    try {
      let content = localStorage.getItem('manager');
      if (!content) return console.error("We don't have a company list");
      manager = JSON.parse(content);

      if (!manager || !manager.companies || manager.companies.length === 0)
        return console.error('No companies for this company manager');
      manager.companies = manager.companies.sort((a, b) => (a.name < b.name ? -1 : 1));
      setCompanies(manager.companies);
      currentCompany = JSON.parse(localStorage.getItem('currentCompany')!);
      if (currentCompany) {
        if (!manager.companies.some(company => company.id === currentCompany.id))
          return console.log('Could not find the selected company in the company list');
        setSelectedItem(currentCompany);
      }
    } catch (e) {
      console.log(e);
      // do something ?
    }
  }, []);

  // TODO: What should we do here ?
  if (!companies || companies.length === 0) return <div />;

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (companies.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedItem(companies[index]);
    localStorage.setItem('currentCompany', JSON.stringify(companies[index]));
    window.location.reload();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          disabled={companies.length <= 1}
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
          id="company-select-button"
        >
          <Typography style={{ color: 'white', fontSize: '16px' }}>
            {selectedItem ? selectedItem.name : 'Choisir une entreprise'}
          </Typography>
          {Boolean(!anchorEl) && <ArrowDropDownIcon style={{ marginLeft: '10px' }} />}
        </ListItem>
      </List>
      {companies.length > 1 && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {companies.map((company, index) => (
            <MenuItem
              key={company.id}
              selected={Boolean(selectedItem && company.id === selectedItem.id)}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              id={`company-select-${index}`}
            >
              {company.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}

const CustomUserMenu = translate(({ ...props }: any) => (
  <div id="app-bar-profile-div">
    <UserMenu {...props} />
  </div>
));

const CustomAppBar = ({ ...props }: any) => {
  const logout = <div id="logout-button">{props.logout}</div>;
  const classes = styles();
  const theme = useTheme();
  return (
    <AppBar
      {...props}
      userMenu={<CustomUserMenu />}
      logout={logout}
      style={{ background: theme.palette.primary.main }}
    >
      <CompanyChooser />
      <Typography color="inherit" className={classes.title} id="react-admin-title" />
      <img
        className={classes.logo}
        alt=""
        src={require('../images/white-gymlib-logo.png')}
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};
export default CustomAppBar;
