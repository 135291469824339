import { ACTION_TYPES } from './types';

export function whitelistReducers(
  state: any = { whitelist: [], activeChallenge: null },
  action: { type: ACTION_TYPES; payload: any }
) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_WHITELIST:
      return {
        ...state,
        whitelist: action.payload.whitelist,
      };

    default:
      return state;
  }
}

export function whitelistPaginatedReducers(
  state: {
    whitelist: Array<{ [key: string]: any }>;
    company: { [key: string]: any };
    total: number;
    search: string;
    integrationType: any;
    responseAsString: boolean;
  } = {
    whitelist: [],
    company: {},
    total: 0,
    search: '',
    integrationType: [],
    responseAsString: false,
  },
  action: { type: ACTION_TYPES; payload: any }
) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_WHITELIST_PAGINATED:
      return {
        ...state,
        whitelist: action.payload.whitelist,
        company: action.payload.company,
        total: action.payload.total,
        search: action.payload.search,
        integrationType: action.payload.integrationType,
        responseAsString: !!action.payload.responseAsString,
      };
    case ACTION_TYPES.ADD_EMAIL_WHITELIST:
      return {
        ...state,
        whitelist: state.whitelist.concat(action.payload.authorizedUser),
      };
    case ACTION_TYPES.ADD_AUTHORIZED_USER_WHITELIST:
      return {
        ...state,
        whitelist: state.whitelist.concat(action.payload.authorizedUser),
      };
    default:
      return state;
  }
}

export function challengeReducers(
  state: any = { whitelist: [], activeChallenge: null },
  action: { type: ACTION_TYPES; payload: any }
) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CHALLENGE:
      return {
        ...state,
        activeChallenge: action.payload.challenge,
      };
    case ACTION_TYPES.DELETE_CHALLENGE:
      return {
        ...state,
        activeChallenge: null,
      };
    default:
      return state;
  }
}
