import { AllPossibleRequestResolver } from '../../../../@types/dataProvider';

// import { Update } from './Update';
import { Create } from './Create';
import { Delete } from './Delete';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    // UPDATE: Update(introspection),
    CREATE: Create(),
    DELETE: Delete(introspection),
  };
};
