import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useMutation, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Snackbar, Paper } from '../../components';
import { updateWhitelistAction } from '../../redux/actions';
import { EMAIL_REGEX, formatEmail } from '../../utils';
import { athensGray, blueGem, shark } from '../../utils/colors';
import UpdateWhitelistDialog from './updateWhitelistDialog';
import { LocalCompany } from '../../@types/common';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Button, Avatar } from '@material-ui/core';

const useAddEmail = makeStyles({
  addEmail: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    color: shark,

    '&:hover': {
      backgroundColor: athensGray,
    },

    '& p': {
      margin: '16px 10px',
    },
  },
  validateNewEmail: {
    border: `1px solid ${blueGem}`,
    display: 'flex',
    padding: '8px',

    '& > :first-child': {
      flexGrow: 1,
      paddingLeft: '60px',
    },
    '& > :not(:first-child)': {
      padding: '0 25px',
    },
  },
});

const useTableHead = makeStyles({
  cells: {
    fontWeight: 'bold',
  },
});

const useToolbarStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  searchIcon: {
    marginRight: '5px',
  },
  searchLabel: {
    padding: '2px 10px',
    margin: 'auto 10px',
    display: 'inline-flex',
    maxWidth: '200px',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid black',
  },
  selectedTitle: {
    alignItems: 'center',
    display: 'flex',
    background: athensGray,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',

    '& > :first-child': {
      flexGrow: 1,
      fontWeight: 'bold',
    },
  },
  uploadButton: {
    letterSpacing: '0.75px',
    borderRadius: '20px',
    whiteSpace: 'nowrap',
  },
});

const useStyles = makeStyles({
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  tableFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'none',
  },
});

interface EnhancedAddEmailProps {
  companyID: string;
  whitelist: string[];
}

const customTheme = createTheme({
  palette: {
    secondary: {
      // works
      main: '#fff',
      contrastText: '#808080',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const EnhancedAddEmail = (props: EnhancedAddEmailProps) => {
  const classes = useAddEmail();
  const dispatch = useDispatch();
  const t = useTranslate();
  const { companyID, whitelist } = props;
  const [displayAddEmail, setDisplayAddEmail] = useState(true);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const [createWhitelist, { data, loading }] = useMutation({
    type: 'create',
    resource: 'Whitelisv2',
    payload: {
      data: { input: { companyID, emails: [formatEmail(newEmail)].concat(whitelist) } },
    },
  });

  const handleAddEmail = (email: string) => {
    if (whitelist.includes(email)) setDisplaySnackbar(true);
    else {
      createWhitelist();
      setNewEmail('');
    }
  };

  useEffect(() => {
    if (!data || !data.whitelist) return;
    dispatch(updateWhitelistAction(data.whitelist.emails));
  }, [data, dispatch]);

  return displayAddEmail ? (
    <div className={classes.addEmail} onClick={() => setDisplayAddEmail(false)}>
      <AddIcon />
      <p>{t('whitelist.current.addEmail.label')}</p>
    </div>
  ) : (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleAddEmail(newEmail);
      }}
      className={classes.validateNewEmail}
    >
      <Input
        type="email"
        disableUnderline
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
      />
      <MuiButton
        color="primary"
        onClick={() => {
          setNewEmail('');
          setDisplayAddEmail(true);
          setDisplaySnackbar(false);
        }}
        type="button"
      >
        {t('whitelist.current.addEmail.cancel')}
      </MuiButton>
      <MuiButton
        color="primary"
        disabled={loading || !EMAIL_REGEX.test(newEmail)}
        type="submit"
      >
        {t('whitelist.current.addEmail.add')}
      </MuiButton>
      <Snackbar
        status="error"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={displaySnackbar}
        onClose={() => setDisplaySnackbar(false)}
        message={t('whitelist.current.addEmail.duplicate')}
      />
    </form>
  );
};

interface EnhancedTableHeadProps {
  numSelected: number;
  setSelected: any;
  whitelist: string[];
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const classes = useTableHead();
  const t = useTranslate();
  const { numSelected, setSelected, whitelist } = props;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setSelected(whitelist);
    else setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < whitelist.length}
            checked={whitelist.length > 0 && numSelected === whitelist.length}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        <TableCell className={classes.cells}>
          {t('whitelist.current.table.email.label')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  selected: string[];
  search: string;
  setDisplayDialog: any;
  setNewWhitelist: any;
  setSearch: any;
  whitelist: string[];
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const t = useTranslate();
  const {
    selected,
    search,
    setDisplayDialog,
    setNewWhitelist,
    setSearch,
    whitelist,
  } = props;

  const handleDeleteClick = () => {
    const newWhitelist = whitelist.filter(email => !selected.includes(email));

    setNewWhitelist(newWhitelist);
    setDisplayDialog(true);
  };

  return selected.length > 0 ? (
    <Toolbar className={classes.selectedTitle}>
      <Typography component="div">
        {t('whitelist.current.toolbar.selected', { smart_count: selected.length })}
      </Typography>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classes.root}>
      <h3>{t('whitelist.current.toolbar.title')}</h3>
      <label className={classes.searchLabel}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          type="search"
          placeholder={t('whitelist.current.search.placeholder')}
          disableUnderline
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </label>
    </Toolbar>
  );
};

function getPaginatedResults<T>({
  items,
  page,
  perPage,
  count,
}: {
  items: T[];
  count: number;
  page: number;
  perPage: number;
}) {
  if (!items || page * perPage > count) return [];
  items.slice(page * perPage, (page + 1) * perPage);
  return items.slice(page * perPage, (page + 1) * perPage);
}

const CurrentWhitelist = ({
  whitelist,
  refresh,
}: {
  whitelist: string[];
  refresh: () => void;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filteredEmails, setFilteredEmails] = useState(whitelist);
  const [paginatedEmails, setPaginatedEmails] = useState(whitelist);
  const [newWhitelist, setNewWhitelist] = useState((null as unknown) as string[]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);

  const handleSelectClick = (event: React.MouseEvent<unknown>, email: string) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected: string[] = [];

    if (selectedIndex === -1) newSelected = newSelected.concat(selected, email);
    else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setPaginatedEmails(
      getPaginatedResults({
        items: filteredEmails,
        count: filteredEmails.length,
        page,
        perPage,
      })
    );
  }, [page, perPage, filteredEmails]);

  useEffect(() => {
    if (!search) setFilteredEmails(whitelist);
    else {
      const options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
      };
      const fuse = new Fuse(whitelist, options);
      const result = fuse.search(search);
      setFilteredEmails(result.map((idx: any) => whitelist[idx]));
      setPage(0);
    }
  }, [search, whitelist]);

  const handleClick = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    let body = JSON.stringify({
      variables: { id: currentCompany.id },
      query: `query oauthGoogle($id: ID!) {\n  data: BO_OauthGoogle(id: $id) {\n    oauth_url  }\n}`,
    });
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'apollo-require-preflight': 'true',
      },
      credentials: 'include',
      body: body,
    })
      .then(result => result.json())
      .then(e => {
        console.error(e);
        window.open(e.data.data.oauth_url, '_blank');
      });
  };

  const handleClickSyncUsers = () => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'apollo-require-preflight': 'true',
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query syncUsers($id: ID!) {\n  data: SyncUsers(id: $id) {\n    emails  }\n}`,
      }),
    })
      .then(async result => {
        await result.json();
        refresh();
      })
      .then(error => {
        console.error(error);
      });
  };

  return (
    <Paper>
      <div>
        <Button variant="contained" color="primary" onClick={handleClickSyncUsers}>
          {'Synchronization'}
        </Button>

        <ThemeProvider theme={customTheme}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClick}
            startIcon={
              // eslint-disable-next-line react/jsx-no-undef
              <Avatar
                src={
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/768px-Google_%22G%22_Logo.svg.png'
                }
              />
            }
          >
            {'Create Google integration'}
          </Button>
        </ThemeProvider>
      </div>
      <EnhancedTableToolbar
        selected={selected}
        whitelist={whitelist}
        search={search}
        setDisplayDialog={setDisplayDialog}
        setNewWhitelist={setNewWhitelist}
        setSearch={setSearch}
      />
      <Table>
        <EnhancedTableHead
          whitelist={whitelist}
          numSelected={selected.length}
          setSelected={setSelected}
        />
        <TableBody>
          {paginatedEmails.map((email: string) => (
            <TableRow key={email} selected={isSelected(email)}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(email)}
                  color="primary"
                  key={email}
                  onClick={event => handleSelectClick(event, email)}
                />
              </TableCell>
              <TableCell align="left">{email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EnhancedAddEmail companyID={currentCompany.id} whitelist={whitelist} />
      <div className={classes.tableFooter}>
        <TablePagination
          component="div"
          count={filteredEmails.length}
          onPageChange={(_, page) => setPage(page)}
          labelRowsPerPage={t('whitelist.current.rowsPerPageLabel')}
          onRowsPerPageChange={e => setPerPage(parseInt(e!.target!.value))}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={perPage}
        />
      </div>
      <UpdateWhitelistDialog
        companyID={currentCompany.id}
        displayDialog={displayDialog}
        handleClose={() => setDisplayDialog(false)}
        newWhitelist={newWhitelist}
        setSelected={setSelected}
      />
    </Paper>
  );
};

export default CurrentWhitelist;
