import React, { forwardRef } from 'react';
import { UserMenu } from 'react-admin';
import { Typography } from '@material-ui/core';
import { Manager } from '../@types/common';

const EmailItem = forwardRef<HTMLHeadingElement>(function someName(props, ref) {
  let content = localStorage.getItem('manager');
  if (!content) return null;
  const manager: Manager = JSON.parse(content);
  if (manager && manager.email) {
    return (
      <Typography
        ref={ref}
        component="h1"
        variant="caption"
        style={{ margin: '15px', marginTop: '5px' }}
      >
        {manager.email}
      </Typography>
    );
  } else return null;
});

const MyUserMenu = (props: any) => (
  <UserMenu {...props}>
    <EmailItem />
  </UserMenu>
);

export default MyUserMenu;
