import { blueGem, scarpaFlow, selago, shark } from './utils/colors';

export default {
  palette: {
    type: 'light',
    primary: {
      main: blueGem,
    },
  },
  typography: {
    h4: {
      fontSize: '34px',
      letterSpacing: '0.25px',
    },
    title: {
      fontWeight: 400,
    },
    fontFamily: 'Poppins, sans-serif',
  },
  overrides: {
    MuiDrawer: {
      root: {
        backgroundColor: selago,
        width: '320px',
      },
      paperAnchorLeft: {
        width: '320px!important',
      },
      docked: {
        // borderRight: `1px solid ${blueGem}`,
      },
    },
    MuiTypography: {
      body2: {
        fontSize: '1rem',
      }
    },
    MuiTab: {
      wrapper: {
        letterSpacing: '0.75px',
      },
    },
    MuiTableCell: {
      head: { color: shark, fontWeight: 'bold' },
      body: { color: scarpaFlow },
    },
    MuiList: {
      root: {
        fontWeight: 500,
        fontSize: '20px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
};
