import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DefaultTheme } from '@material-ui/styles/defaultTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { useMutation, useTranslate } from 'react-admin';
import { emerald, amaranth, frenchGray, shark, white } from '../../utils/colors';
import { Button } from '../../components';
import { createEvent } from '../../utils/analytics';
import { AuthorizedUser } from '../../@types/common';
import { WhitelistLoadingDialog } from './noWhitelist';

export enum Referer {
  UPLOAD_CSV = 'UPLOAD_CSV',
  DELETED = 'DELETED',
}

const useWrapperStyles = makeStyles<DefaultTheme, { isAddition: boolean }>({
  container: { padding: '0 64px', color: shark, maxWidth: '669px' },
  list: {
    maxHeight: '100px',
    overflowY: 'scroll',
    '& >p': { fontSize: '14px', margin: '10px 0' },
  },
  summary: {
    color: props => (props.isAddition ? emerald : amaranth),
    fontSize: '14px',
    margin: '5px 0',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& >.delIcon': { color: amaranth },
    '& >.addIcon': { color: emerald },
    '& >.title': { marginLeft: '10px', fontSize: '18px' },
  },
  onlyDeletionWrapper: {
    padding: '0 64px',
    color: shark,
    boxSizing: 'border-box',
    width: '669px',
    textAlign: 'center',
    '& > .iconWrapper': {
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '48px',
      height: '48px',
      background: amaranth,
      borderRadius: '4px',
      marginBottom: '16px',
      '& >.icon': {
        color: white,
        fontSize: '24px',
      },
    },
    '& > .summary': {
      color: amaranth,
      fontWeight: 500,
      fontSize: '24px',
      marginBottom: '16px',
    },
  },
});

const useStyles = makeStyles({
  close: { position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' },
  noDiffLabel: { margin: '0 20px' },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > :first-child': { margin: '0 0 5px 0' },

    '& >button': {
      margin: '5px 0',
      borderRadius: '20px',
    },
  },
  container: {
    padding: '48px 0 24px 0',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    marginBottom: '32px',
  },
  summaryDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '42px 76px',

    '& >.icon': {
      color: emerald,
      marginBottom: '20px',
      fontSize: '40px',
    },

    '& .text': {
      textAlign: 'center',
      margin: 0,
    },

    '& > :last-child': {
      width: '100%',

      '& .confirmButton': {
        fontSize: '14px',
        marginTop: '30px',
        borderRadius: '20px',
        width: '100%',
      },
    },
  },
});

interface EnhancedAdditionWrapperProps {
  addedAuthorizedUsers: AuthorizedUser[];
}

const EnhancedAdditionWrapper = (props: EnhancedAdditionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: true });
  const t = useTranslate();
  const { addedAuthorizedUsers } = props;

  return (
    <DialogContentText>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <CheckCircleIcon className="addIcon" />
          <span className="title">
            {t('whitelist.updateDialog.addedEmails.title', {
              smart_count: addedAuthorizedUsers.length,
            })}
          </span>
        </div>
        <p className={classes.summary}>
          {t('whitelist.updateDialog.addedEmails.text', {
            smart_count: addedAuthorizedUsers.length,
          })}
        </p>
        <div className={classes.list}>
          {addedAuthorizedUsers.map((employee: AuthorizedUser, index: number) => (
            <p key={index}>
              {employee.refKey} {employee.email ? `- ${employee.email}` : ''}
            </p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

interface EnhancedDeletionWrapperProps {
  deletedAuthorizedUsers: AuthorizedUser[];
}

const EnhancedDeletionWrapper = (props: EnhancedDeletionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: false });
  const t = useTranslate();
  const { deletedAuthorizedUsers } = props;

  return (
    <DialogContentText>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <CancelIcon className="delIcon" />
          <span className="title">
            {t('whitelist.updateDialog.deletedEmails.title', {
              smart_count: deletedAuthorizedUsers.length,
            })}
          </span>
        </div>
        <p className={classes.summary}>
          {t('whitelist.updateDialog.deletedEmails.text', {
            smart_count: deletedAuthorizedUsers.length,
          })}
        </p>
        <div className={classes.list}>
          {deletedAuthorizedUsers.map((employee: AuthorizedUser, index: number) => (
            <p key={index}>
              {employee.refKey} {employee.email ? `- ${employee.email}` : ''}
            </p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

const EnhancedOnlyDeletionWrapper = (props: EnhancedDeletionWrapperProps) => {
  const classes = useWrapperStyles({ isAddition: false });
  const t = useTranslate();
  const { deletedAuthorizedUsers } = props;

  return (
    <DialogContentText>
      <div className={classes.onlyDeletionWrapper}>
        <div className="iconWrapper">
          <InfoOutlinedIcon className="icon" />
        </div>
        <p className="summary">
          {t('whitelist.updateDialog.onlyDeletedEmails.text', {
            smart_count: deletedAuthorizedUsers.length,
          })}
        </p>
        <div className={classes.list}>
          {deletedAuthorizedUsers.map((employee: AuthorizedUser, index: number) => (
            <p key={index}>
              {employee.refKey} {employee.email ? `- ${employee.email}` : ''}
            </p>
          ))}
        </div>
      </div>
    </DialogContentText>
  );
};

const Separator = () => {
  return <div style={{ background: frenchGray, height: '1px', margin: '20px 0' }} />;
};

const AuthorizedUserDetails: FunctionComponent<{
  addedAuthorizedUsers: AuthorizedUser[];
  deletedAuthorizedUsers: AuthorizedUser[];
}> = ({ addedAuthorizedUsers, deletedAuthorizedUsers }) => {
  const classes = useStyles();
  const t = useTranslate();

  if (addedAuthorizedUsers.length)
    return (
      <>
        <EnhancedAdditionWrapper addedAuthorizedUsers={addedAuthorizedUsers} />
        <Separator />
        {Boolean(deletedAuthorizedUsers.length) && (
          <EnhancedDeletionWrapper deletedAuthorizedUsers={deletedAuthorizedUsers} />
        )}
      </>
    );
  else if (deletedAuthorizedUsers.length)
    return (
      <>
        <EnhancedOnlyDeletionWrapper deletedAuthorizedUsers={deletedAuthorizedUsers} />
        <Separator />
      </>
    );
  else
    return (
      <span className={classes.noDiffLabel}>
        {t('whitelist.updateDialog.noDifferences.desc')}
      </span>
    );
};

const DialogActionsWrapper = ({
  classes,
  setPage,
  setSearch,
  deleted,
  setIntegrationType,
  createWhitelist,
  loading,
  deletedLoading,
  referer,
  handleClose,
}: {
  classes: any;
  setPage: any;
  setSearch: any;
  deleted: any;
  setIntegrationType: any;
  createWhitelist: any;
  loading: any;
  deletedLoading: any;
  referer: any;
  handleClose: any;
}) => {
  const [understood, setUnderstood] = useState(false);
  const t = useTranslate();

  return (
    <DialogActions disableSpacing className={classes.actionsWrapper}>
      <FormControlLabel
        style={{ letterSpacing: '0.5px' }}
        control={
          <Checkbox
            style={{ marginRight: '10px' }}
            color="primary"
            checked={understood}
            onChange={() => setUnderstood(!understood)}
          />
        }
        label={t('whitelist.updateDialog.buttons.checkbox')}
      />
      <Button
        variant="contained"
        onClick={() => {
          if (referer === Referer.DELETED) {
            setPage(0);
            setSearch('');
            deleted();
            setIntegrationType([]);
          }
          if (referer === Referer.UPLOAD_CSV) {
            setPage(0);
            setSearch('');
            createWhitelist();
            setIntegrationType([]);
            createEvent('whitelist_updated');
          }
        }}
        color="primary"
        className="confirmButton"
        disabled={!understood || loading || deletedLoading}
      >
        {t('whitelist.updateDialog.buttons.confirm')}
      </Button>
      <MuiButton
        onClick={handleClose}
        color="primary"
        disabled={loading || deletedLoading}
      >
        {t('whitelist.updateDialog.buttons.cancel')}
      </MuiButton>
    </DialogActions>
  );
};

const UpdateWhitelistWithRefKeysDialog = ({
  companyID,
  displayDialog,
  handleClose,
  setSelected,
  addedAuthorizedUsers = [],
  deletedAuthorizedUsers = [],
  total,
  fetchData,
  referer,
  uploadAuthorizedUsers,
  setIntegrationType,
  setSearch,
  setPage,
}: {
  companyID: string;
  displayDialog: boolean;
  handleClose: () => void;
  setSelected: any;
  addedAuthorizedUsers: AuthorizedUser[];
  deletedAuthorizedUsers: AuthorizedUser[];
  total: number;
  fetchData: (
    page: number,
    perPage: number,
    search?: string,
    integrationType?: string
  ) => void;
  referer: string;
  uploadAuthorizedUsers: any;
  setIntegrationType: any;
  setSearch: any;
  setPage: any;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [uploadFinished, setUploadFinished] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [understood, setUnderstood] = useState(false);

  const [createWhitelist, { data, loading }] = useMutation({
    type: 'create',
    resource: 'Whitelist',
    payload: {
      data: {
        input: {
          companyID,
          authorizedUsers: JSON.stringify(uploadAuthorizedUsers.current),
        },
      },
    },
  });

  const [deleted, { data: deletedData, loading: deletedLoading }] = useMutation({
    type: 'delete',
    resource: 'Whitelist',
    payload: {
      input: {
        companyID,
        users: deletedAuthorizedUsers.map((e: any) => e.id),
      },
    },
  });

  useEffect(() => {
    if (!data || !data.whitelist) return;
    fetchData(0, 10);
    setUploadFinished(true);
    setSelected([]);
  }, [data, fetchData, setSelected, loading]);

  useEffect(() => {
    if (!deletedData) return;
    fetchData(0, 10);
    setUploadFinished(true);
    setSelected([]);
  }, [deletedData, fetchData, setSelected, deletedLoading]);

  useEffect(() => {
    if (!displayDialog) {
      if (uploadFinished) setUploadFinished(false);
      setUnderstood(false);
    }
  }, [displayDialog, uploadFinished]);

  const UploadSection = () => (
    <>
      {Boolean(!deletedAuthorizedUsers.length || addedAuthorizedUsers.length) && (
        <DialogTitle className={classes.title}>
          {t('whitelist.updateDialog.title')}
        </DialogTitle>
      )}
      <CloseIcon className={classes.close} onClick={handleClose} />
      <DialogContent>
        <AuthorizedUserDetails
          addedAuthorizedUsers={addedAuthorizedUsers}
          deletedAuthorizedUsers={deletedAuthorizedUsers}
        />
      </DialogContent>
      <DialogActionsWrapper
        classes={classes}
        setPage={setPage}
        setSearch={setSearch}
        deleted={deleted}
        setIntegrationType={setIntegrationType}
        createWhitelist={createWhitelist}
        loading={loading}
        deletedLoading={deletedLoading}
        referer={referer}
        handleClose={handleClose}
      />
      {(loading || deletedLoading) && (
        <WhitelistLoadingDialog
          isOpen={loading || deletedLoading}
          usersCount={total || 0}
        />
      )}
    </>
  );

  const SummarySection = () => (
    <div className={classes.summaryDialog}>
      <CheckCircleIcon fontSize="inherit" className="icon" />
      <DialogContent>
        <p className="text">{t('whitelist.updateDialog.summary.title')}</p>
        <p className="text">
          {t('whitelist.updateDialog.summary.text', {
            smart_count: total,
          })}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          className="confirmButton"
          color="primary"
        >
          {t('whitelist.updateDialog.summary.button')}
        </Button>
      </DialogActions>
    </div>
  );

  if (!addedAuthorizedUsers.length && !deletedAuthorizedUsers.length) return null;

  return (
    <Dialog
      open={displayDialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <div className={classes.container}>
        {!uploadFinished ? <UploadSection /> : <SummarySection />}
      </div>
    </Dialog>
  );
};

export default UpdateWhitelistWithRefKeysDialog;
