import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDataProvider, useTranslate } from 'react-admin';
import CurrentWhitelist from './currentWhitelist';
import NoWhitelist from './noWhitelist';
import { useSelector, useDispatch } from 'react-redux';
import { updateWhitelistAction } from '../../redux/actions';

const WhitelistTabs = () => {
  const t = useTranslate();
  const [tabValue, setTabValue] = useState(0);
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(true);
  const whitelist = useSelector((state: any) => state.whitelistReducers.whitelist);
  const dispatch = useDispatch();

  const loadData = () => {
    setIsLoading(true);
    const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
    dataProvider
      .getOne('CorporateGoogleCompany', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        dispatch(
          updateWhitelistAction(
            data.validEmployeeEmailsv2 ? data.validEmployeeEmailsv2 : []
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
    dataProvider
      .getOne('CorporateGoogleCompany', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        dispatch(
          updateWhitelistAction(
            data.validEmployeeEmailsv2 ? data.validEmployeeEmailsv2 : []
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dataProvider, dispatch]);

  if (isLoading) return null;

  if (!whitelist.length) {
    return <NoWhitelist />;
  }

  return (
    <div style={{ padding: '32px' }}>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
      >
        <Tab label={t('whitelist.tabs.currentTitle')} onClick={() => setTabValue(0)} />
        <Tab label={t('whitelist.tabs.olderTitle')} onClick={() => setTabValue(1)} />
      </Tabs>
      {tabValue === 0 && <CurrentWhitelist whitelist={whitelist} refresh={loadData} />}
    </div>
  );
};

export default WhitelistTabs;
