import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const GET_ONE_QUERY = gql`
  query DH_challengeTemplate($id: ID!) {
    data: DH_challengeTemplate(id: $id) {
      id
      name
    }
  }
`;

export const GetOne = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_ONE', 'ChallengeTemplate', params),
      // Override the query
      query: GET_ONE_QUERY,
      parseResponse: (resp: any) => {
        if (!resp.data) return { data: {} };
        return { data: resp.data.data };
      },
    };
  };
};
