import { IResourceExport } from '../../@types/dataProvider';

import hrManagerProvider from './dataProvider';

export default {
  name: 'HrManager',
  dataProvider: {
    provider: hrManagerProvider,
  },
} as IResourceExport;
