import corporationGoogleProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'CorporateGoogleCompany',
  dataProvider: {
    getOneName: 'companyGoogle',
    provider: corporationGoogleProvider,
  },
} as IResourceExport;
