import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const GET_LIST_QUERY = gql`
  query hrHistoryAuthorizeUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CompanyAuditFilter
  ) {
    items: hrHistoryAuthorizeUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      fromHrManager
      fromUser
      whitelistSize
      createdAt
    }
    total: _hrHistoryAuthorizeUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetList = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);
    params.filter = { companyID: currentCompany.id };
    return {
      ...builtQuery('GET_LIST', 'Whitelist', params),
      query: GET_LIST_QUERY,
      parseResponse: (resp: any) => {
        const whitelists = resp.data.items.map((whitelist: any) => {
          whitelist.name = `${currentCompany.name}`;

          return whitelist;
        });
        return { data: whitelists, total: resp.data.total.count };
      },
    };
  };
};
