import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  CssBaseline,
  Card,
  Container,
  CircularProgress,
} from '@material-ui/core';

import {
  useNotify,
  useDataProvider,
  useTranslate,
  useRedirect,
  Notification,
} from 'react-admin';
import { sleep } from '../utils';
import { LocalCompany } from '../@types/common';
import { Field, Form } from 'react-final-form';
import { managerLogin } from '../authProvider/login';
import { Button, TextField } from '../components';

const PASSWORD_REGEX = /(?=.*[0-9])(?=.*[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ])(?=.*[A-ZÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]).{8,}/;

interface ChangePasswordProps {
  match: any;
}

const styles = makeStyles({
  paper: {
    margin: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    marginTop: '15px',
  },
  submit: {
    marginTop: '30px',
  },
  formControl: {
    display: 'flex',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    height: '45px',
  },
  logoContainer: {
    height: '15vh',
    minHeight: '100px',
    display: 'flex',
  },
  icon: {
    marginRight: '5px',
  },
});

interface FormData {
  password1: string;
  password2: string;
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  match,
}: ChangePasswordProps) => {
  const classes = styles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const t = useTranslate();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [canUpdatePassword, setCanUpdatePassword] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isPasswordInitialization, setIsPasswordInitialization] = useState(false);

  useEffect(() => {
    dataProvider
      .getOne('PasswordReset', {
        id: match.params.id,
      })
      .then((res: any) => {
        if (res.data.status === 'PENDING' && new Date(res.data.expiredAt) > new Date()) {
          setIsPasswordInitialization(res.data.isPasswordInitialization);
          setEmail(res.data.sourceEmail);
          setCanUpdatePassword(true);
        } else {
          setCanUpdatePassword(false);
        }
        setFirstLoad(false);
      })
      .catch(() => {
        notify(t('pages.passwordReset.expiredLink'), 'warning');
        setCanUpdatePassword(false);
        setFirstLoad(false);
      });
    // eslint-disable-next-line
  }, []);

  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      await dataProvider.update('HrManager', {
        data: {
          input: {
            passwordResetID: match.params.id,
            newPassword: values.password1,
          },
        },
      });
      notify(t('pages.passwordReset.passwordChanged'), 'info');
      const managerInfo = await managerLogin(email, values.password1);
      const companies: LocalCompany[] = managerInfo.manager.companies.map(company => ({
        id: company.id,
        name: company.name,
        hasFeed: !!company.hasFeed,
        hasChallenge: !!company.hasChallenge,
        usersCanRegisterWithIdentifier: !!company.usersCanRegisterWithIdentifier,
      }));
      localStorage.setItem(
        'logStatus',
        Math.random()
          .toString(24)
          .slice(2)
      );
      localStorage.setItem(
        'manager',
        JSON.stringify({ email: managerInfo.manager.email, companies })
      );
      localStorage.setItem(
        'currentCompany',
        JSON.stringify(
          companies.sort((a: LocalCompany, b: LocalCompany) =>
            a.name < b.name ? -1 : 1
          )[0]
        )
      );
      await sleep(3000);
      redirect('/');
    } catch (e) {
      await sleep(500);
      setLoading(false);
      if (e instanceof Error) {
        if (e.message.endsWith('InvalidPasswordReset')) {
          notify(t('pages.passwordReset.invalidURL'));
        } else if (e.message.endsWith('ExpiredPasswordReset')) {
          notify(t('pages.passwordReset.expiredURL'));
        } else if (e.message.endsWith('PasswordTooWeakException')) {
          notify(t('pages.passwordReset.tooWeakPasswordError'));
        } else if (e.message.endsWith('ServerError')) {
          notify(t('pages.passwordReset.serverError'));
        } else {
          notify(t('pages.passwordReset.classicError'));
        }
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.logoContainer}>
        <img className={classes.logo} alt="" src={require('../images/logo.png')} />
      </div>
      <Card>
        <div className={classes.paper}>
          {canUpdatePassword && !firstLoad && (
            <>
              <Typography component="h1" variant="h5">
                {isPasswordInitialization
                  ? t('pages.passwordInit.title')
                  : t('pages.passwordReset.title')}
              </Typography>
              {email !== '' && (
                <Typography component="caption" variant="caption">
                  {'(' + email + ')'}
                </Typography>
              )}
              <Form
                onSubmit={submit}
                validate={values => {
                  const errors = { password1: '', password2: '' };
                  if (!values.password1) {
                    errors.password1 = t('ra.validation.required');
                    return errors;
                  }
                  if (values.password1 && !PASSWORD_REGEX.test(values.password1)) {
                    errors.password1 = t('pages.passwordReset.tooWeakPasswordInput');
                    return errors;
                  }
                  if (!values.password2) {
                    errors.password2 = t('ra.validation.required');
                    return errors;
                  }
                  if (
                    values.password1 &&
                    values.password2 &&
                    values.password1 !== values.password2
                  ) {
                    errors.password2 = t('pages.passwordReset.notSamePasswordInput');
                    return errors;
                  }
                  if (values.password2 && !PASSWORD_REGEX.test(values.password2)) {
                    errors.password2 = t('pages.passwordReset.tooWeakPasswordInput');
                    return errors;
                  }
                  return {};
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Field
                      id="password-1"
                      label="Mot de passe"
                      autoComplete="password-1"
                      name="password1"
                      type="password"
                      disabled={loading}
                    >
                      {field => (
                        <TextField
                          error={!!(field.meta.touched && field.meta.error)}
                          helperText={field.meta.touched && field.meta.error}
                          autoFocus
                          margin="normal"
                          required
                          fullWidth
                          variant="outlined"
                          {...field.input}
                          {...field}
                        />
                      )}
                    </Field>
                    <Field
                      id="password-2"
                      label={t('pages.passwordReset.retypePassword')}
                      autoComplete="password-2"
                      name="password2"
                      type="password"
                      disabled={loading}
                    >
                      {field => (
                        <TextField
                          error={!!(field.meta.touched && field.meta.error)}
                          helperText={field.meta.touched && field.meta.error}
                          margin="normal"
                          required
                          fullWidth
                          variant="outlined"
                          {...field.input}
                          {...field}
                        />
                      )}
                    </Field>
                    <Button
                      type="submit"
                      fullWidth
                      color="primary"
                      variant="contained"
                      id="submit-button"
                      className={classes.submit}
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress
                          className={classes.icon}
                          size={18}
                          thickness={2}
                        />
                      )}
                      {isPasswordInitialization
                        ? t('pages.passwordInit.button')
                        : t('pages.passwordReset.button')}
                    </Button>
                  </form>
                )}
              </Form>
            </>
          )}
          {!canUpdatePassword && (
            <Typography component="h1" variant="h5">
              {firstLoad
                ? t('pages.passwordReset.loading')
                : t('pages.passwordReset.wrongLink')}
            </Typography>
          )}
        </div>
      </Card>
      <Notification id="snack-bar" autoHideDuration={3000} />
    </Container>
  );
};
export default ChangePassword;
