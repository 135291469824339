import gql from 'graphql-tag';

const UPDATE_CORPORATE_COMPANY_MUTATION = gql`
  mutation updateCorporateCompany($input: DH_UpdateCompanyInput!) {
    data: DH_updateCompany(input: $input) {
      company {
        id
        isSocialOnboarded
      }
    }
  }
`;

export const Update = (_: any) => {
  return (params: any) => {
    if (
      params.data.input &&
      params.data.input.id &&
      params.data.input.isSocialOnboarded
    ) {
      console.log('[UPDATE][CORPORATE_COMPANY]', params);

      return {
        query: UPDATE_CORPORATE_COMPANY_MUTATION,
        variables: { ...params.data },
        parseResponse: (resp: any) => {
          if (resp.data.data.company.id) {
            return { data: resp.data.data.company };
          }
        },
      };
    }
  };
};
