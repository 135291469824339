import DefaultTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { blueGem, black } from '../utils/colors';

const TextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: black,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: blueGem,
      },
    },
  },
})(DefaultTextField);

export default TextField;
