import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { amaranth, white } from '../../../utils/colors';
import { IChampionship } from '../utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDeleteMany, useTranslate } from 'react-admin';
import { SnackbarStatus } from '../../../components/Snackbar';

const useStyles = makeStyles({
  dialog: {
    padding: 24,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 750,
  },
  iconWrapper: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    background: amaranth,
    borderRadius: '4px',
    marginBottom: '16px',
    '& >.icon': {
      color: white,
      fontSize: '24px',
    },
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: 28,
    color: amaranth,
    marginBottom: 16,
  },
  deleteListContainer: {
    maxHeight: 105,
    overflowY: 'auto',
    marginBottom: 16,
  },
  deleteListItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    justifyContent: 'center',
  },
  deleteListTextFirstPart: {
    marginRight: 4,
  },
  deleteListTextSecondPart: {
    fontStyle: 'italic',
  },
  contentDividers: {
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkbox: {
    marginTop: 18,
    marginBottom: 12,
  },
  button: {
    marginTop: 8,
    marginBottom: 8,
    width: 320,
    borderRadius: 200,
  },
});

interface DeleteDialogProps {
  openDialog: boolean;
  hideDialog: (cleanSelection: boolean) => void;
  total: number;
  championshipsToDelete: IChampionship[];
  onSuccess: () => void;
  setSnackbar: ({
    status,
    open,
    message,
  }: {
    status: SnackbarStatus;
    open: boolean;
    message: string;
  }) => void;
}

const DeleteDialog = ({
  championshipsToDelete,
  hideDialog,
  setSnackbar,
  openDialog,
  total,
  onSuccess,
}: DeleteDialogProps) => {
  const classes = useStyles();
  const t = useTranslate();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [deleteChampionships, { loading, error, data }] = useDeleteMany(
    'Championship',
    championshipsToDelete.map(companyOffice => companyOffice.id)
  );

  const Header = () => (
    <>
      <div className={classes.iconWrapper}>
        <InfoOutlinedIcon className="icon" />
      </div>
      {total > championshipsToDelete.length ? (
        <DialogTitle className={classes.headerTitle}>
          {t('companyOffice.list.deleteDialog.resumeDelete', {
            smart_count: championshipsToDelete.length,
          })}
        </DialogTitle>
      ) : (
        <DialogTitle className={classes.headerTitle}>
          {t('companyOffice.list.deleteDialog.cannotDeleteEveryGroup')}
        </DialogTitle>
      )}
    </>
  );

  const DeleteList = () => (
    <div className={classes.deleteListContainer}>
      {championshipsToDelete.map(championship => (
        <div className={classes.deleteListItem} key={championship.name}>
          <Typography variant="body2" className={classes.deleteListTextFirstPart}>
            {championship.name}
          </Typography>
        </div>
      ))}
    </div>
  );

  const Content = () => (
    <DialogContent dividers classes={{ root: classes.contentDividers }}>
      {total > championshipsToDelete.length ? (
        <>
          <Typography variant="body1" color="primary" style={{ textAlign: 'center' }}>
            {t('companyOffice.list.deleteDialog.content')}
          </Typography>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={(_, checked) => setCheckboxChecked(checked)}
                color="primary"
              />
            }
            label={t('companyOffice.list.deleteDialog.checkbox')}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!checkboxChecked}
            className={classes.button}
            onClick={deleteChampionships}
          >
            {t('companyOffice.list.deleteDialog.confirmDeletion')}
          </Button>
          <Button
            onClick={() => {
              hideDialog(false);
              setCheckboxChecked(false);
            }}
            color="primary"
            className={classes.button}
          >
            {t('companyOffice.list.deleteDialog.cancelDeletion')}
          </Button>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            color="primary"
            style={{ textAlign: 'center', marginBottom: 16 }}
          >
            {t('companyOffice.list.deleteDialog.cannotDeleteContent1')}
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'center', marginBottom: 16 }}>
            {t('companyOffice.list.deleteDialog.cannotDeleteContent2')}
          </Typography>
          <Button
            onClick={() => {
              hideDialog(false);
              setCheckboxChecked(false);
            }}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            {t('companyOffice.list.deleteDialog.cancelDeletion')}
          </Button>
        </>
      )}
    </DialogContent>
  );

  useEffect(() => {
    if (!loading && data) {
      hideDialog(true);
      setCheckboxChecked(false);
      onSuccess();
      // refresh();
      // setTimeout(
      //   () =>
      //     setSnackbar({
      //       status: 'success',
      //       open: true,
      //       message: t('companyOffice.list.deleteDialog.snackbarSuccess', {
      //         smart_count: championshipsToDelete.length,
      //       }),
      //     }),
      //   500
      // );
    } else if (!loading && error) {
      hideDialog(true);
      setCheckboxChecked(false);
      setSnackbar({
        status: 'error',
        open: true,
        message: t('companyOffice.list.deleteDialog.snackbarError', {
          smart_count: championshipsToDelete.length,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => hideDialog(false)}
      classes={{ paper: classes.dialog }}
    >
      <Header />
      {total > championshipsToDelete.length && <DeleteList />}
      <Content />
    </Dialog>
  );
};

export default DeleteDialog;
