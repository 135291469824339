import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

import { resolveIntrospection } from './introspection';

import { AllPossibleRequestResolver, IResourceExport } from '../@types/dataProvider';

interface IProviders {
  [resourceName: string]: AllPossibleRequestResolver;
}
/**
 * A nasty way to catch session expired exception before they are too deep in the RA gql provider
 */
const responseParserWrapper = (originalResponseParser: (_: any) => any) => (data: {
  errors: undefined | any;
  data: any;
}) => {
  const errors = data.errors;
  if (
    errors &&
    errors[0].statusCode === 401 &&
    (errors[0].name === 'SessionExpiredException' ||
    errors[0].name === 'UnauthorizedException' ||
      errors[0].message === 'Not Logged In')
  )
    throw new Error('USER_NOT_AUTHENTICATED');
  return originalResponseParser(data);
};

const myBuildQuery = (resources: { [key: string]: IResourceExport }) => (
  introspection: any
) => {
  const providers: IProviders = {};
  Object.values(resources).forEach(resource => {
    if (resource.dataProvider && resource.name && resource.dataProvider.provider) {
      providers[resource.name] = resource.dataProvider.provider(introspection);
    }
  });

  return (fetchType: string, resource: string, params: any) => {
    console.log(fetchType, resource, params);
    if (resource in providers) {
      if (providers[resource][fetchType]) {
        const provider = providers[resource][fetchType](params);

        const keepingIt = provider.parseResponse;
        provider.parseResponse = responseParserWrapper(keepingIt);
        return provider;
      }
    }

    const builtQuery = buildQuery(introspection)(fetchType, resource, params);

    let defaultParseResponse = builtQuery.parseResponse;

    builtQuery.parseResponse = responseParserWrapper(defaultParseResponse);

    return builtQuery;
  };
};

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    if (
      ['changeHrManagerPassword', 'createPasswordReset'].includes(
        operation.operationName
      ) &&
      graphQLErrors
    ) {
      graphQLErrors[0].message = graphQLErrors[0].name;
    }
  }
);

export default (resources: { [key: string]: IResourceExport }) => {
  const forcedTypes: string[] = [];
  const types: { [key: string]: string } = {};
  Object.values(resources).forEach(resource => {
    if (!resource.resources) {
      forcedTypes.push(resource.name);
      types[resource.dataProvider.getOneName] = resource.name;
    } else {
      if (resource.dataProvider.shouldForce) forcedTypes.push(resource.name);
      types[resource.dataProvider.getOneName] = resource.name;
    }
  });
  return buildGraphQLProvider({
    clientOptions: {
      link: errorLink.concat(
        createHttpLink({
          uri: process.env.REACT_APP_API_URL,
          fetch,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            'apollo-require-preflight': true,
          },
          credentials: 'include',
        })
      ),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    },
    resolveIntrospection: resolveIntrospection(types, forcedTypes),
    buildQuery: myBuildQuery(resources),
  });
};
