import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetList } from './Many';
import { Create } from './Create';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_LIST: GetList(introspection),
    CREATE: Create(introspection),
  };
};
