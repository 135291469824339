import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/styles/makeStyles';
import { athensGray } from '../../utils/colors';
import { ChevronRight } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  cellChampionShipName: {
    borderRight: `1px solid ${athensGray}`,
    color: '#212936',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  teamInfo: {
    color: '#525268',
    fontSize: '14px',
  },
  teamName: {
    paddingLeft: '3rem',
  },
  showTeamsLabel: {
    color: '#525268',
    fontSize: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  showTeamsLabelIcon: {
    fontSize: '1.4em',
  },
  showTeamsLabelIconRotated: {
    rotate: '90deg',
  },
  championShipNameLabel: {
    flexGrow: 1,
  },
});

const ChampionshipDetails = ({
  node,
  translate,
  challengeStatus,
  handleChampionshipSelected,
}: any) => {
  const styles = useStyles();
  const [showTeams, setShowTeams] = useState(false);

  const handleToggleShowTeams = () => {
    setShowTeams(!showTeams);
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.cellChampionShipName}>
          <Checkbox
            color="primary"
            disabled={challengeStatus !== 'DRAFT'}
            onChange={event => handleChampionshipSelected(event.target.checked, node)}
          />
          <span className={styles.championShipNameLabel}>{node.name}</span>
          {showTeams && (
            <span
              className={styles.showTeamsLabel}
              onClick={() => handleToggleShowTeams()}
            >
              <span> {translate('challenges.teams.showTeams')}</span>
              <ChevronRight
                className={[
                  styles.showTeamsLabelIcon,
                  styles.showTeamsLabelIconRotated,
                ].join(' ')}
              />
            </span>
          )}
          {!showTeams && (
            <span
              className={styles.showTeamsLabel}
              onClick={() => handleToggleShowTeams()}
            >
              <span> {translate('challenges.teams.showTeams')}</span>
              <ChevronRight className={styles.showTeamsLabelIcon} />
            </span>
          )}
        </TableCell>
        <TableCell align="right">{node.channel.userCount}</TableCell>
      </TableRow>
      {showTeams &&
        Array.isArray(node.teams.edges) &&
        node.teams.edges.map(
          ({ cursor, node: team }: { cursor: any; node: any; channel: any }) => (
            <TableRow key={cursor} className={styles.teamInfo}>
              <TableCell className={styles.teamName}>{team.name}</TableCell>
              <TableCell align="right">{team.channel.userCount || 0}</TableCell>
            </TableRow>
          )
        )}
    </>
  );
};

export default ChampionshipDetails;
