import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetOne } from './GetOne';
import { Create } from './Create';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne(introspection),
    CREATE: Create(introspection),
  };
};
