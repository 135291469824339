import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { SnackbarStatus } from '../../../components/Snackbar';
import { amaranth, black, blueGem, emerald, midGrey } from '../../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    padding: 24,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 750,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: 28,
    color: black,
    marginBottom: 24,
  },
  addListContainer: {
    maxHeight: 105,
    overflowY: 'auto',
    marginTop: 12,
  },
  button: {
    margin: 'auto',
    display: 'flex',
    marginTop: 24,
    marginBottom: 8,
    minWidth: 320,
    borderRadius: 200,
  },
  table: {
    width: 450,
    marginBottom: 24,
  },
  tableHeader: {
    backgroundColor: midGrey,
  },
  cell: {
    padding: 10,
    textAlign: 'center',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  descriptionFirstPart: {
    color: blueGem,
  },
  successIcon: {
    color: emerald,
    marginRight: 12,
  },
  errorIcon: {
    color: amaranth,
    marginRight: 12,
  },
  textSuccess: {
    color: emerald,
  },
  textError: {
    color: amaranth,
  },
  state3Title: {
    paddingLeft: 100,
    paddingRight: 100,
    marginTop: 32,
    textAlign: 'center',
  },
  contentState2: {
    padding: 24,
    paddingLeft: 40,
    alignSelf: 'flex-start',
  },
  contentState2Divider: {
    borderBottom: '1px solid',
    borderBottomColor: midGrey,
    width: '100%',
    height: 1,
  },
  state2IconContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  checkbox: {
    marginTop: 18,
    marginBottom: 12,
    marginLeft: 16,
  },
});

interface IAddChampionshipProps {
  openDialog: boolean;
  onCloseDialog: () => void;
  championshipsToAdd: string[];
  handleUploadChampionships: () => void;
  setSnackbar: ({
    status,
    open,
    message,
  }: {
    status: SnackbarStatus;
    open: boolean;
    message: string;
  }) => void;
}

export default ({
  openDialog,
  onCloseDialog,
  championshipsToAdd,
  handleUploadChampionships,
}: IAddChampionshipProps) => {
  const classes = useStyles();
  const t = useTranslate();

  const ItemsToAddList = ({ list }: { list: string[] }) => {
    return (
      <div className={classes.addListContainer}>
        <ul>
          {list.map(item => (
            <li key={Date.now()}>
              <Typography variant="body2">{item}</Typography>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.headerTitle}>
        {t('challenges.createChampionshipsDialog.createChampionships')}
      </DialogTitle>
      <DialogContent>
        <span>{t('championships.list.addDialog.content')}</span>
        <ItemsToAddList list={championshipsToAdd}></ItemsToAddList>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleUploadChampionships}
        >
          {t('championships.list.addDialog.buttonYes')}
        </Button>
        <Button color="primary" className={classes.button} onClick={onCloseDialog}>
          {t('championships.createChampionshipsDialog.cancelButton')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
