import MuiPaper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const Paper = withStyles({
  root: {
    maxWidth: '997px',
  },
})(MuiPaper);

export default Paper;
