import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query ListUsersGoogleWorkspace($id: ID!) {
    data: ListUsersGoogleWorkspace(id: $id) {
      users {
        email
        organizationalUnit
        ignoredUser
      }
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: { data: any }) => {
      if (!resp.data) return { data: {} };
      return { data: resp.data.data };
    },
  };
};
